import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  user: false,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const updateUser = createAsyncThunk();

export const updateuserSlice = createSlice({
  name: "updateuser",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(updateUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.profile = action.payload;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export default updateuserSlice.reducer;
