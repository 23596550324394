import React, { useState, useRef } from "react";
import axios from "axios";
import { AuthUser } from "../../../context/Auth";

const Nationality = ({ ...props }) => {
  const { authUrl } = AuthUser();
  const [newNationality, setNewNationality] = useState();
  const nat = useRef(null);

  const handleClearForm = () => {
    nat.current.value = "";
    setNewNationality("");
  };

  const handleAddNewNationality = async (e) => {
    e.preventDefault();
    const nationalityData = e.target.dataset.nat;

    if (nationalityData) {
      try {
        await axios
          .post(`${authUrl}/addNewNat`, {
            data: {
              nationality: nationalityData,
            },
          })
          .then((response) => console.log(response.data));
        props.sequ();
      } catch (error) {
        console.log(error.response.data);
      }
    } else {
      console.log("Input field cannot be null !!");
    }
  };

  return (
    <div
      className={
        props.nationalityAdd === "0" ? "hide" : "nationality-container"
      }
    >
      <input
        ref={nat}
        name="nationality"
        id="nationality"
        type="text"
        onChange={(e) => {
          setNewNationality(e.target.value);
        }}
      />
      <button
        type="button"
        data-nat={newNationality}
        onClick={(e) => {
          handleAddNewNationality(e);
          props.setNationalityAdd("0");
          handleClearForm();
        }}
      >
        Add New
      </button>
    </div>
  );
};

export default Nationality;
