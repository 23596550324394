import React from "react";
import { useNavigate } from "react-router-dom";
import { AuthUser } from "../../context/Auth.jsx";

const Top = () => {
  const navigate = useNavigate();
  const { isLoggedIn } = AuthUser();
  //const isLoggedIn = JSON.parse(localStorage.getItem("user"));

  /* LOGOUT */
  const handleLogout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("userUpdate");
    sessionStorage.removeItem("session");
    navigate("/");
    window.location.reload();
  };

  return (
    <div className="top">
      {isLoggedIn && (
        <div className="top-logout-container">
          <button onClick={() => handleLogout()}>Logout</button>
        </div>
      )}
    </div>
  );
};

export default Top;
