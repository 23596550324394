import React, { useState } from "react";

const RadioSize = ({ ...props }) => {
  let [clothActive, setClothActive] = useState();
  let uData = props.userData;

  if (clothActive === 0) {
    clothActive = 0;
  } else if (!clothActive && uData?.clothes) {
    clothActive = uData?.clothes;
  }

  return (
    <div className="clothes-container">
      <span className="cloth-title">Dress size:</span>
      <div className="cloth-size">
        <p
          className={clothActive === 0 ? "selected" : ""}
          data-name="clothes"
          data-clothes="0"
          onClick={(e) => {
            setClothActive(0);
            props.handleClothes(e);
          }}
        >
          xs
        </p>
      </div>
      <div className="cloth-size">
        <p
          className={clothActive === 1 ? "selected" : ""}
          data-name="clothes"
          data-clothes="1"
          onClick={(e) => {
            setClothActive(1);
            props.handleClothes(e);
          }}
        >
          xs/s
        </p>
      </div>
      <div className="cloth-size">
        <p
          className={clothActive === 2 ? "selected" : ""}
          data-name="clothes"
          data-clothes="2"
          onClick={(e) => {
            setClothActive(2);
            props.handleClothes(e);
          }}
        >
          s
        </p>
      </div>
      <div className="cloth-size">
        <p
          className={clothActive === 3 ? "selected" : ""}
          data-name="clothes"
          data-clothes="3"
          onClick={(e) => {
            setClothActive(3);
            props.handleClothes(e);
          }}
        >
          s/m
        </p>
      </div>
      <div className="cloth-size">
        <p
          className={clothActive === 4 ? "selected" : ""}
          data-name="clothes"
          data-clothes="4"
          onClick={(e) => {
            setClothActive(4);
            props.handleClothes(e);
          }}
        >
          m
        </p>
      </div>
      <div className="cloth-size">
        <p
          className={clothActive === 5 ? "selected" : ""}
          data-name="clothes"
          data-clothes="5"
          onClick={(e) => {
            setClothActive(5);
            props.handleClothes(e);
          }}
        >
          m/l
        </p>
      </div>
      <div className="cloth-size">
        <p
          className={clothActive === 6 ? "selected" : ""}
          data-name="clothes"
          data-clothes="6"
          onClick={(e) => {
            setClothActive(6);
            props.handleClothes(e);
          }}
        >
          l
        </p>
      </div>
      <div className="cloth-size">
        <p
          className={clothActive === 7 ? "selected" : ""}
          data-name="clothes"
          data-clothes="7"
          onClick={(e) => {
            setClothActive(7);
            props.handleClothes(e);
          }}
        >
          xl
        </p>
      </div>
      <div className="cloth-size">
        <p
          className={clothActive === 8 ? "selected" : ""}
          data-name="clothes"
          data-clothes="8"
          onClick={(e) => {
            setClothActive(8);
            props.handleClothes(e);
          }}
        >
          xxl
        </p>
      </div>
    </div>
  );
};

export default RadioSize;
