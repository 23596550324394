import { Routes, Route, Outlet } from "react-router-dom";
import Login from "../pages/Login.jsx";
import Error404 from "../pages/Errorpage.jsx";
import { AuthUser } from "../context/Auth.jsx";
import Spinner from "../components/Spinner.js";
import AddData from "../pages/users/AddData.jsx";
import UpdateData from "../pages/users/UpdateData.jsx";
import FindData from "../pages/users/FindData.jsx";
import ProfileData from "../pages/users/ProfileData.jsx";
import SettingsData from "../pages/users/SettingsData.jsx";
import ClientsData from "../pages/users/ClientsData.jsx";
import ProjectData from "../pages/users/ProjectData.jsx";
import LeftSide from "../components/mainpage/LeftSide.jsx";
import Info from "../pages/users/find/Info.jsx";
import Gallery from "../pages/users/find/Gallery.jsx";
import Jobs from "../pages/users/find/Jobs.jsx";
import Cards from "../pages/users/find/Cards.jsx";
import Social from "../pages/users/find/Social.jsx";
import Stats from "../pages/users/find/Stats.jsx";

const routes = () => {
  const { userId, loading } = AuthUser();

  const RequireAuth = ({ children }) => {
    return <>{loading ? <Spinner /> : <>{userId ? children : <Login />}</>}</>;
  };

  return (
    <main>
      {/* <LeftSide />*/}
      <Routes>
        <Route path="/" element={<Login />} />
        <Route
          path="/dashboard"
          exact
          element={
            <RequireAuth>
              <Outlet />
            </RequireAuth>
          }
        >
          <Route path="/dashboard/" element={<FindData />} />
          <Route path="/dashboard/users/find" element={<FindData />} />
          <Route path="/dashboard/users/find/info" element={<Info />} />
          <Route path="/dashboard/users/find/gallery" element={<Gallery />} />
          <Route path="/dashboard/users/find/jobs" element={<Jobs />} />
          <Route path="/dashboard/users/find/cards" element={<Cards />} />
          <Route path="/dashboard/users/find/social" element={<Social />} />
          <Route path="/dashboard/users/find/stats" element={<Stats />} />
          <Route path="/dashboard/users/update/:id" element={<UpdateData />} />
          <Route path="/dashboard/users/add" element={<AddData />} />
          <Route path="/dashboard/users/profiles" element={<ProfileData />} />
          <Route path="/dashboard/users/settings" element={<SettingsData />} />
          <Route path="/dashboard/users/projects" element={<ProjectData />} />
          <Route path="/dashboard/users/clients" element={<ClientsData />} />
        </Route>
        <Route path="*" element={<Error404 />} />
      </Routes>
    </main>
  );
};
export default routes;
