const FormData = ({ ...props }) => {
  return props.fieldsData.map((getData) => (
    <div key={getData.id}>
      <div
        className={props.showForm === false && "hide"}
        className={getData.classType}
      >
        <label>{getData.label}</label>
        <input
          id={getData.id}
          name={getData.fieldName}
          type={getData.type}
          placeholder={getData.placeholder}
          defaultValue={getData.defaultValue}
          onChange={(e) => {
            props.handleForm(e);
            props.validateFields(e);
          }}
        />
      </div>
      <div className="error-message">
        {getData.fieldName === "email" && props.ckEmail}
        {getData.fieldName === "fullname" && props.ckFullName}
        {getData.fieldName === "birthday" && props.ckBirthday}
        {getData.fieldName === "height" && props.ckHeight}
        {getData.fieldName === "phone" && props.ckPhone}
      </div>
    </div>
  ));
};

export default FormData;
