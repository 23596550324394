/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable default-case */
import React, { useEffect, useState } from "react";
import { AuthUser } from "../../context/Auth.jsx";
//import LeftSide from "../../components/mainpage/LeftSide";
import { NavLink } from "react-router-dom";
import axios from "axios";
import dummyImgX from "../../assets/female.jpg";
import dummyImgY from "../../assets/male.jpg";
import Filters from "../../components/users/Filters.jsx";

const FindData = () => {
  const { imgUrl, url } = AuthUser();
  let [userData, setUserData] = useState([]);
  let [keyMap, setKeyMap] = useState("Tag");
  let [mapName, setMapName] = useState("");
  let [tagList, setTagList] = useState([]);
  const [tagListData, setTagListData] = useState([]);
  const [tags, setTags] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [restoreData, setRestoreData] = useState([]);
  // const [errorNoData] = useState("No Results Found !");
  const [height, setHeight] = useState([0, 220]);
  const [age, setAge] = useState([0, 100]);
  const [shoe, setShoe] = useState([0, 46]);
  const [clothes, setClothes] = useState([0, 15]);
  const [gender, setGender] = useState("");
  const [limit, setLimit] = useState(25);
  const [count, setCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [hideLimitButtons, setHideLimitButtons] = useState(true);
  // const [formatTags, setFormatTags] = useState([]);
  const noImage = "/images/no-photo.png";

  /* Handle No Image Error */
  const handleImageError = (e) => {
    e.target.src = noImage;
  };

  /* REFRESH DATA */
  useEffect(() => {
    if (restoreData.length === 0) {
      sequ();
      filterData();
    } else {
      filterData();
    }
    localStorage.removeItem("userData");
  }, [
    mapName,
    //    keyMap,
    filteredData.length,
    userData.length,
    tags,
    clothes,
    age,
    shoe,
    height,
    gender,
  ]);

  /* REFINE DATA */
  const convertData = (val) => {
    if (isNaN(val)) {
      return val.toUpperCase().replace(/ /g, "");
    } else if (!isNaN(val)) {
      return val;
    }
  };

  /* READ DATABASE / SET VARIABLES */
  const sequ = async () => {
    try {
      const response = await axios.get(`${url}/seq`);
      /* Set Users Data from API*/
      setUserData(response.data.users);
      setRestoreData(response.data);
      setTotalCount(response.data.users.length);
    } catch (error) {
      console.log(error);
    }
  };

  /* START FILTERS */
  const filterData = async () => {
    try {
      if (restoreData.filters) {
        let filtered = [];
        if (keyMap === "id" && !isNaN(mapName)) {
          filtered = restoreData.users.filter(
            (filter) => filter.id == mapName || filter.phone == mapName
          );
        } else if (keyMap === "Tag") {
          filtered = restoreData.users.filter(
            (filter) =>
              mapName.length !== 0 &&
              filter.features.find((getIf) => getIf.feat.includes(mapName)) &&
              filter.age >= age[0] &&
              filter.age <= age[1] &&
              filter.height >= height[0] &&
              filter.height <= height[1] &&
              filter.shoe >= shoe[0] &&
              filter.shoe <= shoe[1] &&
              filter.clothes >= clothes[0] &&
              filter.clothes <= clothes[1] &&
              filter.gender.includes(gender)
          );
        } else if (keyMap === "name") {
          filtered = restoreData.users.filter(
            (filter) =>
              convertData(`${filter.firstname}${filter.lastname}`).includes(
                convertData(mapName)
              ) &&
              filter.age >= age[0] &&
              filter.age <= age[1] &&
              filter.height >= height[0] &&
              filter.height <= height[1] &&
              filter.shoe >= shoe[0] &&
              filter.shoe <= shoe[1] &&
              filter.clothes >= clothes[0] &&
              filter.clothes <= clothes[1] &&
              filter.gender.includes(gender)
          );
        }
        if (!mapName /* && keyMap === "name" && keyMap !== "Tag" && */) {
          filtered = restoreData.users.filter((filter) => filter);
        }

        /* TAG LIST FILTER */
        applyTagListFilter();

        /* FIRST FILTER */
        setFilteredData(filtered);
        setUserData(filtered);

        /* SECOND FILTER */
        applyFilter();

        /* Clear Filter List if No More Results */
        if (userData.length <= 1) {
          setTagList([]);
        }
      }
      if (userData.length < totalCount) {
        setLimit(userData.length);
      } else {
        setLimit(25);
      }
    } catch (error) {
      console.log(error);
    }
  };

  /* APPLY FILTERS */
  const applyFilter = async () => {
    let returnFilter = [];
    try {
      tags.forEach((tag) => {
        /* Conditioning UserData */
        if (tags.length > 1) {
          if (tagList.length === 0) {
            returnFilter = filteredData;
          } else returnFilter = userData;
        } else returnFilter = filteredData;
        /* Start Filter */
        const filteredExtra = returnFilter.filter(function (read) {
          const keyFound = read.features.find(function (findBy) {
            if (findBy !== undefined) {
              return findBy.feat.includes(tag.feat);
            }
          });
          if (keyFound !== undefined) {
            return keyFound;
          }
        });
        setUserData(filteredExtra);
      });
    } catch (error) {
      console.log(error);
    }
  };

  /* APPLY TAG LIST FILTERS */
  const applyTagListFilter = async () => {
    try {
      setTagList([]);
      userData.filter(function (read) {
        read.features.map((getUser, index) => {
          setTagList((ret) => [...new Set(ret.concat(getUser.feat))]);
        });
      });
    } catch (error) {
      console.log(error);
    }
  };

  /* --- HANDLE TAGS FILTERS */
  const handleTagFilters = async (e) => {
    try {
      /* Filter Tag Name To Be Set */
      const notExist = tagListData.filter((addFilter) => {
        return addFilter.feat !== e.target.dataset.feat;
      });
      /* Set Variables */
      setTagListData(notExist.concat(e.target.dataset));
      setTags(notExist.concat(e.target.dataset));
    } catch (error) {
      console.log(error);
    }
  };

  /* HANDLE REMOVE TAGS FILTERS */
  const handleRemoveTag = (e) => {
    /* Check Tag Name if Exist */
    const keyFound = tags.find((findBy) =>
      findBy.extrafeatid.includes(e.target.dataset.extrafeatid)
    );
    /* Filter Tag Name To Be Removed */
    const notExist = tags.filter(
      (addFilter) => addFilter.extrafeatid !== keyFound.extrafeatid
    );
    /* Set Variables */
    if (tags.length <= 0) {
    } else {
      setTagListData(notExist);
      setTags(notExist);
    }
  };

  /* HANDLE CLEAR SLIDERS */
  const handleClearSliders = () => {
    setHeight([0, 220]);
    setAge([0, 100]);
    setShoe([0, 46]);
    setClothes([0, 15]);
  };

  /* RETURN TEMPLATE */
  return (
    <div className="find-data-container">
      <Filters
        setKeyMap={setKeyMap}
        setTags={setTags}
        setTagList={setTagList}
        setTagListData={setTagListData}
        sequ={sequ}
        value={keyMap}
        mapName={mapName}
        setMapName={setMapName}
        tagList={tagList}
        tags={tags}
        handleRemoveTag={handleRemoveTag}
        setFilteredData={setFilteredData}
        setUserData={setUserData}
        handleTagFilters={handleTagFilters}
        tagListData={tagListData}
        applyTagListFilter={applyTagListFilter}
        age={age}
        setAge={setAge}
        setHeight={setHeight}
        height={height}
        shoe={shoe}
        setShoe={setShoe}
        clothes={clothes}
        setClothes={setClothes}
        secondFilterData={filterData}
        clearSliders={handleClearSliders}
        setGender={setGender}
      />
      <div className="counter">
        {userData.length} - Results Found &nbsp; / &nbsp;&nbsp;
        {count + limit} - Limit Show Results
      </div>
      <div className="counter-container">
        <div
          className={
            hideLimitButtons === false
              ? "counter buttons"
              : "counter buttons close-modal"
          }
        >
          <div className="button-close">
            <span
              className="material-icons"
              onClick={() => setHideLimitButtons(true)}
            >
              arrow_back_ios
            </span>
          </div>
          <button onClick={() => setLimit(0)}>Show All</button>
          <button onClick={() => setLimit(25)}>Show 25</button>
          <button
            onClick={() => {
              setLimit(limit + 25);
              if (limit >= userData.length) {
                setLimit(userData.length);
                setCount(userData.length - userData.length);
              }
            }}
          >
            Add 25 More
          </button>
          <div className={hideLimitButtons === true ? "button-close" : "hide"}>
            <span
              className="material-icons"
              onClick={() => setHideLimitButtons(false)}
            >
              arrow_forward_ios
            </span>
          </div>
        </div>
      </div>

      <div className="find-main-container">
        {userData
          .filter((results, index) => (limit ? index <= limit : results))
          .map((res, index) => {
            let image = "";
            /* if (res.profileimage) {
              image = (
                <img
                  src={`${imgUrl}/${res.profileimage}`}
                  alt="profile"
                  onError={(e) => handleImageError(e)}
                />
              );
            } else */ if (!res.profileimage) {
              image = <img src={`${imgUrl}/${image}`} alt="profile" />;
            } else if (res.gender == 2) {
              image = <img src={dummyImgX} alt="profilfemale" />;
            } else if (res.gender == 1 || res.gender == 3) {
              image = <img src={dummyImgY} alt="profilmale" />;
            }

            return (
              <div key={index} className="list-users">
                <figure>
                  {image}
                  <figcaption>
                    <div className="user-details">
                      <p className="user-name">
                        {
                          res.firstname +
                            " " +
                            res.lastname /*.substring(0, 1)*/
                        }
                      </p>
                      <p className="user-height">Height: {res?.height}</p>
                      <p className="user-age">Age: {res?.age}</p>
                      <p className="user-shoes">Shoes: {res?.shoe}</p>
                      <p className="user-clothes">Clothes: {res?.clothes}</p>
                    </div>
                    <div className="user-containers">
                      <div className="user-social">
                        <div className="user-images">
                          <span className="material-icons">image</span>
                        </div>
                        <div className="user-video">
                          <span className="material-icons">videocam</span>
                        </div>
                        <div className="user-shared">
                          <span className="material-icons">share</span>
                        </div>
                      </div>
                      <div className="user-edit">
                        <NavLink to={`/dashboard/users/update/${res.id}`}>
                          <span className="material-icons">tune</span>
                        </NavLink>
                      </div>
                    </div>
                  </figcaption>
                </figure>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default FindData;
