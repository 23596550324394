import React, { useState } from "react";

const Exclusive = ({ ...props }) => {
  const [period, setPeriod] = useState("0");

  return (
    <div className="exclusive-container">
      <span className="exclusive-title">Exclusive ?</span>
      <div className="exclusive-select">
        <div
          id="3"
          data-name="endingdate"
          data-endingdate={props.defaultEndDate3}
          onClick={(e) => {
            setPeriod("3");
            props.handleExclusive(e);
          }}
        >
          <span
            className={
              period === "3" ? "material-icons filled" : "material-icons clear"
            }
          >
            star
          </span>
          <p className={period === "3" ? "exclusive-text" : ""}>3 years</p>
        </div>
      </div>
      <div className="exclusive-select">
        <div
          id="5"
          data-name="endingdate"
          data-endingdate={props.defaultEndDate5}
          onClick={(e) => {
            setPeriod("5");
            props.handleExclusive(e);
          }}
        >
          <span
            className={
              period === "5" ? "material-icons filled" : "material-icons clear"
            }
          >
            star
          </span>
          <p className={period === "5" ? "exclusive-text" : ""}>5 years</p>
        </div>
      </div>
    </div>
  );
};

export default Exclusive;
