const socialData = [
  {
    id: 1,
    socialName: "Facebook",
    socialLink: "https://www.facebook.com/shinemodels",
    socialIcon: "media-fb",
    target: "_new",
  },
  {
    id: 2,
    socialName: "Twitter",
    socialLink: "https://twitter.com/shine_models",
    socialIcon: "media-tw",
    target: "_new",
  },
  {
    id: 3,
    socialName: "Instagram",
    socialLink: "https://www.instagram.com/shinecasting/",
    socialIcon: "media-ig",
    target: "_new",
  },
  {
    id: 4,
    socialName: "What's Up",
    socialLink: `https://wa.me/40722792333`,
    socialIcon: "media-wa",
    target: "_new",
  },
  {
    id: 5,
    socialName: "Youtube",
    socialLink: "https://www.youtube.com/@shinecastingtalentsagency/videos",
    socialIcon: "media-yt",
    target: "_new",
  },
  {
    id: 6,
    socialName: "Email",
    socialLink: "mailto:office@shine.ro?subject=General Inquiry",
    socialIcon: "media-email",
    target: "_new",
  },
];

export default socialData;
