const ethnicity = [
  {
    id: "e1",
    ethnicity: "caucasian",
  },
  {
    id: "e2",
    ethnicity: "asian",
  },
  {
    id: "e3",
    ethnicity: "arab",
  },
  {
    id: "e4",
    ethnicity: "indian",
  },
  {
    id: "e5",
    ethnicity: "afro american",
  },
  {
    id: "e6",
    ethnicity: "hispanic",
  },
];

export default ethnicity;
