const gender = [
  {
    id: "e1",
    prefix: "1",
    gender: "male",
  },
  {
    id: "e2",
    prefix: "2",
    gender: "female",
  },
  {
    id: "e3",
    prefix: "3",
    gender: "non binary",
  },
];

export default gender;
