const eyeColor = [
  {
    id: "f1",
    eyecolor: "black eyes",
  },
  {
    id: "f2",
    eyecolor: "brown eyes",
  },
  {
    id: "f3",
    eyecolor: "blue eyes",
  },
  {
    id: "f4",
    eyecolor: "green eyes",
  },
  {
    id: "f5",
    eyecolor: "hazel eyes",
  },
];

export default eyeColor;
