/* eslint-disable eqeqeq */
import React, { useState } from "react";
import axios from "axios";
import ethnicity from "../../../data/ethnicity.js";
import eyeColor from "../../../data/eyeColor.js";
import hairColor from "../../../data/hairColor.js";
import gender from "../../../data/gender.js";
import Nationality from "./Nationality.jsx";
import { AuthUser } from "../../../context/Auth.jsx";

const Features = ({ ...props }) => {
  const { authUrl } = AuthUser();
  let [eyeValue, setEyeValue] = useState("");
  let [eyeShow, setEyeShow] = useState(false);
  let [hairValue, setHairValue] = useState("");
  let [hairShow, setHairShow] = useState(false);
  let [ethnicityValue, setEthnicityValue] = useState("");
  let [ethnicityShow, setEthnicityShow] = useState(false);
  let [genderValue, setGenderValue] = useState("");
  let [genderShow, setGenderShow] = useState(false);
  let [nationalityValue, setNationalityValue] = useState("");
  const [nationalityShow, setNationalityShow] = useState(false);
  const [nationalityAdd, setNationalityAdd] = useState("0");
  const [showConfirm, setShowConfirm] = useState(0);
  const uData = props.userData;
  const hData = props.featData
    ?.filter((res) => res.ftype == "2")
    .map((res) => res.feat);
  const eData = props.featData
    ?.filter((res) => res.ftype == "1")
    .map((res) => res.feat);
  const etData = props.featData
    ?.filter((res) => res.ftype == "3")
    .map((res) => res.feat);
  const nData = props.featData
    ?.filter((res) => res.ftype == "5")
    .map((res) => res.feat);

  if (!genderValue && uData) {
    switch (uData.gender) {
      case "1":
        genderValue = "male";
        break;
      case "2":
        genderValue = "female";
        break;
      case "3":
        genderValue = "non binary";
        break;
      default:
    }
  }

  if (!nationalityValue && nData) {
    nationalityValue = nData;
  }
  if (!hairValue && hData) {
    hairValue = hData;
  }
  if (!eyeValue && eData) {
    eyeValue = eData;
  }
  if (!ethnicityValue && etData) {
    ethnicityValue = etData;
  }

  const handleDeleteNationality = async (e) => {
    e.preventDefault();
    try {
      await axios
        .delete(`${authUrl}/removeNat`, {
          data: {
            nationality: e.target.dataset.nationality,
          },
        })
        .then((response) => console.log(response.data));
      props.sequ();
    } catch (error) {
      console.log(error.response.data);
    }
  };

  return (
    <div className="features-container ">
      <div className="dropdown">
        <div className="dropdown-button" onClick={() => setEyeShow(true)}>
          <p>EYE COLOUR</p>
        </div>
        <div className="dropdown-selected">{eyeValue}</div>
        <div
          className={
            eyeShow === false ? "dropdown-content" : "dropdown-content show"
          }
        >
          {eyeColor.map((et) => {
            return (
              <div className="dropdown-element" key={et.id}>
                <p
                  data-fname={"eyecolor"}
                  data-value={et.eyecolor}
                  data-id={et.extrafeatid}
                  data-ftype={1}
                  onClick={(e) => {
                    props.handleFormFeatSet(e.target.dataset);
                    setEyeValue(et.eyecolor);
                    setEyeShow(false);
                  }}
                >
                  {et.eyecolor}
                </p>
              </div>
            );
          })}
        </div>
      </div>
      <div className="dropdown">
        <div className="dropdown-button" onClick={() => setHairShow(true)}>
          <p>HAIR COLOUR</p>
        </div>
        <div className="dropdown-selected">{hairValue}</div>
        <div
          className={
            hairShow === false ? "dropdown-content" : "dropdown-content show"
          }
        >
          {hairColor.map((et) => {
            return (
              <div className="dropdown-element" key={et.id}>
                <p
                  data-fname={"haircolor"}
                  data-value={et.haircolor}
                  data-id={et.extrafeatid}
                  data-ftype={2}
                  onClick={(e) => {
                    props.handleFormFeatSet(e.target.dataset);
                    setHairValue(et.haircolor);
                    setHairShow(false);
                  }}
                >
                  {et.haircolor}
                </p>
              </div>
            );
          })}
        </div>
      </div>
      <div className="dropdown">
        <div className="dropdown-button" onClick={() => setEthnicityShow(true)}>
          <p>Ethnicity</p>
        </div>
        <div className="dropdown-selected">{ethnicityValue}</div>
        <div
          className={
            ethnicityShow === false
              ? "dropdown-content"
              : "dropdown-content show"
          }
        >
          {ethnicity.map((et) => {
            return (
              <div className="dropdown-element" key={et.id}>
                <p
                  data-fname={"ethnicity"}
                  data-value={et.ethnicity}
                  data-id={et.extrafeatid}
                  data-ftype={3}
                  onClick={(e) => {
                    props.handleFormFeatSet(e.target.dataset);
                    setEthnicityValue(et.ethnicity);
                    setEthnicityShow(false);
                  }}
                >
                  {et.ethnicity}
                </p>
              </div>
            );
          })}
        </div>
      </div>
      <div className="dropdown">
        <div className="dropdown-button" onClick={() => setGenderShow(true)}>
          <p>GENDER</p>
        </div>
        <div className="dropdown-selected">{genderValue}</div>
        <div
          className={
            genderShow === false ? "dropdown-content" : "dropdown-content show"
          }
        >
          {gender.map((et) => {
            return (
              <div className="dropdown-element" key={et.id}>
                <p
                  data-name="gender"
                  data-value={et.prefix}
                  onClick={(e) => {
                    props.handleGender(e.target.dataset);
                    setGenderValue(et.gender);
                    setGenderShow(false);
                  }}
                >
                  {et.gender}
                </p>
              </div>
            );
          })}
        </div>
      </div>
      <div className="dropdown-add">
        <div className="buttons-container">
          <div
            className="dropdown-button add"
            onClick={(e) => setNationalityShow(true)}
          >
            <p>Nationality</p>
          </div>
          <div
            className="dropdown-add-nationality"
            onClick={(e) => setNationalityShow(false)}
          >
            <span
              className={
                ("material-icons",
                nationalityAdd === "0" ? "material-icons" : "hide")
              }
              onClick={(e) => setNationalityAdd("1")}
            >
              add
            </span>
            <span
              className={
                ("material-icons",
                nationalityAdd === "1" ? "material-icons" : "hide")
              }
              onClick={(e) => setNationalityAdd("0")}
            >
              remove
            </span>
          </div>
          <div
            className={
              ("nationality-container-add",
              nationalityAdd === "0" ? "hide" : "nationality-container-add")
            }
          >
            <Nationality
              sequ={props.sequ}
              nationalityAdd={nationalityAdd}
              setNationalityAdd={setNationalityAdd}
            />
          </div>
        </div>
        <div
          title="On DoubleClick Delete Nationality"
          className="dropdown-selected"
          onDoubleClick={(e) => {
            setShowConfirm(1);
          }}
        >
          {nationalityValue}
        </div>
        {showConfirm === 1 && (
          <div className={"confirm-delete"}>
            <p>Delete Nationality</p>
            <div className="button-container">
              <div
                className="button"
                data-nationality={nationalityValue}
                onClick={(e) => {
                  handleDeleteNationality(e);
                  setNationalityValue("");
                  setShowConfirm(0);
                }}
              >
                YES
              </div>
              <div
                className="button green"
                data-nationality={nationalityValue}
                onClick={(e) => {
                  setShowConfirm(0);
                }}
              >
                NO
              </div>
            </div>
          </div>
        )}
        <div
          className={
            nationalityShow === false
              ? "dropdown-content"
              : "dropdown-content show"
          }
          //  onClick={(e) => setNationalityShow(true)}
        >
          {props.nationality?.map((et) => {
            return (
              <div className="dropdown-element" key={et.nid}>
                <p
                  data-fname={"nationality"}
                  data-value={et.nationality}
                  data-ftype={5}
                  onClick={(e) => {
                    props.handleFormFeatSet(e.target.dataset);
                    setNationalityValue(et.nationality);
                    setNationalityShow(false);
                  }}
                >
                  {et.nationality}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Features;
