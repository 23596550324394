/* eslint-disable eqeqeq */
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { AuthUser } from "../../context/Auth.jsx";
import Form from "../../components/users/Form.jsx";

const AddData = () => {
  const navigate = useNavigate();
  const {
    url,
    authUrl,
    defaultStartDate,
    defaultEndDate3,
    defaultEndDate5,
    // setAddConfirm,
  } = AuthUser();
  const [dragActive, setDragActive] = useState(false);
  const [error, setError] = useState(null);
  const [errorImg, setErrorImg] = useState(null);
  const [image, setUploadImage] = useState();
  let [images, setImages] = useState([]);
  let [imagesPrev, setImagesPrev] = useState([]);
  const [imagesID, setImagesID] = useState();
  const [userText, setUserText] = useState("");
  const [validateForm, setValidateForm] = useState(false);
  const [selectedExtraFeat, setSelectedExtraFeat] = useState([]);
  const [listFeat, setListFeat] = useState([]);
  const [nationality, setNationality] = useState();
  const [dragImg, setDragImg] = useState();
  const [formFeatSet, setFormFeatSet] = useState({
    eyecolor: "",
    ethnicity: "",
    haircolor: "",
    nationality: "",
  });
  const [formData, setFormData] = useState({
    firstname: "no firstname",
    lastname: "no lastname",
    username: "no username",
    role: "user",
    email: "no@email.com",
    password: "",
    birthyear: "1990",
    height: "175",
    gender: "none",
    phone: "00401234567",
    active: "1",
    startingdate: "",
    endingdate: "",
    exclusive: "",
    shoe: "38",
    clothes: "3",
    uploadImage: "",
  });
  const [updateConfirm, setUpdateConfirm] = useState();

  useEffect(() => {
    sequ();
  }, []);

  const sequ = async () => {
    try {
      const response = await axios.get(`${url}/seq`);
      setNationality(response?.data.nat);
      setListFeat(response?.data.extra);
      console.log("Only Nationality and Extra Data Read");
    } catch (error) {
      console.log(error);
    }
  };

  /* Handle Drag File Event */
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  // TRIGGERS WHEN FILE IS DROPPED
  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    setFormData({ ...formData, ["uploadImage"]: e.dataTransfer.files[0] });
    // console.log(e.dataTransfer);
    setDragImg(e.dataTransfer.files[0]);
  };

  // TRIGGERS WHEN FILE IS DROPPED
  const handleDropMulti = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    if (!images) {
      setImages(e.dataTransfer.files);
    } else {
      let temp = images;
      images = Array.prototype.slice
        .call(temp)
        .concat(Array.prototype.slice.call(e.dataTransfer?.files));
      setImages(images);
    }
  };

  /* HANDLE REMOVE IMAGE FROM LIST ----- NOT REQUIRED */
  const handlePreDelete = (e) => {
    const imageId = e.target.dataset.id;
    images = images?.filter((returnedImages, index) => index != imageId);
    setImages(images);
  };

  /* HANDLE RESTORE EXTRA FEATURES TAGS ----- NOT REQUIRED */
  const handleFormFeatReset = (e) => {
    /* Get Data */
    let dataFeat = [
      {
        feat: e.target.dataset.feat,
        id: e.target.dataset.featid,
      },
    ];
    let listId = new Set(dataFeat?.map((get) => get.id));
    /* Filter Data - Set Unselected Data */
    dataFeat = listFeat.concat(dataFeat);
    setListFeat(dataFeat);
    /* Filter Data - Set Selected Data */
    let filtered = selectedExtraFeat.filter((check) => !listId.has(check.id));
    setSelectedExtraFeat(filtered);
  };

  /* Handle Add New Extra Features TAGS ----- NOT REQUIRED */
  const handleAddExtraFeat = (e) => {
    e.preventDefault();
    let dataAdd = e.target.dataset;
    /* Filter Duplicate Selection for TAGS */
    let filtered = selectedExtraFeat.find((remove) =>
      remove.id.includes(dataAdd.id)
    );
    let filterFeat = listFeat.find((remove) => remove.id === dataAdd.id);
    /* Prevent Submiting Duplicates TAGS */
    if (dataAdd.id && !filtered !== false && !filterFeat) {
      const dataFeat = selectedExtraFeat.concat(dataAdd);
      setSelectedExtraFeat(dataFeat);
    }
  };

  /* Handle Extra Features Data  ----- NOT REQUIRED */
  const handleFormFeat = (e) => {
    e.preventDefault();
    /* Proccess TAGS Data */
    let dataExtraFeat = [
      {
        ["feat"]: e.target.dataset.value,
        ["id"]: e.target.dataset.id,
      },
    ];
    /* Create list ID */
    let listId = new Set(dataExtraFeat?.map((get) => get.id));
    let checkId = selectedExtraFeat.filter((check) => listId.has(check.id));
    /* Set Selected And Unselected Lists */
    if (checkId) {
      setSelectedExtraFeat((selectedExtraFeat) => [
        ...new Set(selectedExtraFeat.concat(dataExtraFeat)),
      ]);
      setListFeat(
        listFeat.filter((remove) => remove.id != e.target.dataset.id)
      );
    }
  };

  /* Handle Features Data  ----- NOT REQUIRED */
  const handleFormFeatSet = (e) => {
    setFormFeatSet({
      ...formFeatSet,
      [e.fname]: e.value,
    });
  };

  /* Handle Special Notes Data  ----- NOT REQUIRED */
  const handleText = (e) => {
    setUserText(e.target.value);
  };

  /* Handle Snapshots Images ----- NOT REQUIRED */
  const handleGender = (e) => {
    setFormData({
      ...formData,
      [e.name]: e.value,
    });
  };

  /* Handle Profile Image ----- REQUIRED */
  const handleImage = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.files[0] });
  };

  /* Handle Snapshots Images ----- NOT REQUIRED */
  const handleImages = (e) => {
    // setImages(e.target.files);
    if (!images && e.target.files) {
      setImages(e.target.files);
    } else if (!images && e.dataTransfer?.files) {
      setImages(e.dataTransfer?.files);
    } else if (images && e.target.files) {
      let temp = images;
      images = Array.prototype.slice
        .call(temp)
        .concat(Array.prototype.slice.call(e.target?.files));
      setImages(images);
    } else if (images && e.dataTransfer?.files) {
      let temp = images;
      images = Array.prototype.slice
        .call(temp)
        .concat(Array.prototype.slice.call(e.dataTransfer?.files));
      setImages(images);
    }
  };

  const handleDeleteImage = (image) => {
    //setSelectedImages(selectedImages.filter((e) => e !== image));
    //URL.revokeObjectURL(image);
  };

  /* Handle ID Image ----- NOT REQUIRED */
  const handleImagesID = (e) => {
    setImagesID(e.target.files);
  };

  /* Handle Clothes Size ----- NOT REQUIRED */
  const handleClothes = (e) => {
    setFormData({
      ...formData,
      [e.target.dataset.name]: e.target.dataset.clothes,
    });
  };

  /* Handle Exclusive Data ----- NOT REQUIRED */
  const handleExclusive = (e) => {
    setFormData({
      ...formData,
      [e.currentTarget.dataset.name]: e.currentTarget.dataset.startingdate,
      [e.currentTarget.dataset.name]: e.currentTarget.dataset.endingdate,
    });
  };

  /* Handle Input Fields ----- REQUIRED */
  const handleForm = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  /* Handle Check Data Before Submit */
  const handleValidate = () => {
    if (
      formData?.uploadImage &&
      formData?.firstname &&
      formData?.lastname &&
      formData?.birthyear &&
      formData?.height &&
      //  formData?.gender &&
      formData?.phone
    ) {
      setValidateForm(true);
    } else {
      console.log("Missing Required Form Data", validateForm);
    }
  };

  console.log(formData.uploadImage);

  /* SUBMIT FORM / ADD NEW USER -> API */
  const handleAddUser = async (e) => {
    e.preventDefault();

    /* Check If Form Data is Validated */
    if (validateForm === true) {
      console.log("Form Data Validated !", validateForm);
      const addUpdateData = new FormData();
      const age = new Date().getFullYear() - formData.birthyear;
      addUpdateData.append("username", formData.username);
      addUpdateData.append("email", formData.email);
      addUpdateData.append("password", formData.password);
      addUpdateData.append("role", formData.role);
      addUpdateData.append("firstname", formData.firstname);
      addUpdateData.append("lastname", formData.lastname);
      addUpdateData.append("birthyear", formData.birthyear);
      addUpdateData.append("age", age);
      addUpdateData.append("height", formData.height);
      addUpdateData.append("gender", formData.gender);
      addUpdateData.append("shoe", formData.shoe);
      addUpdateData.append("clothes", formData.clothes);
      addUpdateData.append("phone", formData.phone);
      addUpdateData.append("usertext", userText);
      /*--*/
      if (formFeatSet)
        addUpdateData.append("feat", JSON.stringify(formFeatSet));
      /*--*/
      if (selectedExtraFeat)
        addUpdateData.append("extrafeat", JSON.stringify(selectedExtraFeat));
      /*--*/
      if (!formData.startingdate && formData.endingdate) {
        addUpdateData.append("startingdate", defaultStartDate);
      } else {
        formData.startingdate &&
          addUpdateData.append("startingdate", formData.startingdate);
      }
      /*--*/
      formData.endingdate &&
        addUpdateData.append("endingdate", formData.endingdate);
      /*--*/
      formData.active && addUpdateData.append("active", formData.active);
      /*--*/
      formData.uploadImage &&
        addUpdateData.append("uploadImage", formData.uploadImage);
      addUpdateData.append("profileimage", formData.uploadImage.name);
      /*--*/
      if (images) {
        Object.values(images).forEach((file) => {
          addUpdateData.append("uploadImages", file);
          addUpdateData.append("images", file.name);
        });
      }
      /*--*/
      if (imagesID) {
        Object.values(imagesID).forEach((file) => {
          addUpdateData.append("uploadImagesID", file);
          addUpdateData.append("imagesID", file.name);
        });
      }
      /*--*/
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      /*--*/
      try {
        await axios
          .post(`${authUrl}/seqAdd`, addUpdateData, config)
          .then((response) => {
            setUpdateConfirm("User Added Successfully !");
            setSelectedExtraFeat([]);
            console.log(response.data);
            let redirect = setTimeout(() => {
              navigate(`/dashboard/users/update/${response.data.user}`);
            }, 2000);
          });
      } catch (error) {
        console.log(error);
        setUpdateConfirm("User Add FAIL !");
      }
    } else {
      console.log("Missing Requiered Form Data", validateForm);
    }
  };

  /**
   * @param { Functions As Props } functions : handleShowAdd,handleSubmitExtraFeat,handleAddExtraFeat,
   * removeTag,handleAddUser,handleForm,handleImage,handleTag,handleFormFeat,handleShowAdd,
   * handleAddExtraFeat, handleSubmitExtraFeat, handleRemoveExtraFeat
   * @param { Object } tag
   * @param { Object } image
   * @param { Object } listFeat
   * @param { String } userAdded
   * @param { String } error
   * @param { String } errorImg
   * @param { Boolean } req
   * @param { Boolean } btnShow
   * @param { Boolean } showAdd
   */

  /* SET FORM */
  return (
    <div className="add-data-container" id="reset">
      <div className="add-data-wrapper">
        <Form
          handleForm={handleForm}
          handleAddUser={handleAddUser}
          handleImage={handleImage}
          handleGender={handleForm}
          handleImages={handleImages}
          handleImagesID={handleImagesID}
          handleText={handleText}
          handleClothes={handleClothes}
          handleExclusive={handleExclusive}
          handleFormFeat={handleFormFeat}
          handleFormFeatSet={handleFormFeatSet}
          handleGender={handleGender}
          buttonName={"SUBMIT"}
          images={images}
          defaultEndDate3={defaultEndDate3}
          defaultEndDate5={defaultEndDate5}
          defaultStartDate={defaultStartDate}
          req={true}
          error={error}
          image={dragImg}
          errorImg={errorImg}
          btnShow={false}
          setUpdate={true}
          validateForm={validateForm}
          handleValidate={handleValidate}
          handleFormFeatReset={handleFormFeatReset}
          listFeat={listFeat}
          setListFeat={setListFeat}
          selectedExtraFeat={selectedExtraFeat}
          setSelectedExtraFeat={setSelectedExtraFeat}
          handleAddExtraFeat={handleAddExtraFeat}
          sequ={sequ}
          nationality={nationality}
          handleDrag={handleDrag}
          handleDrop={handleDrop}
          handleDropMulti={handleDropMulti}
          setDragActive={setDragActive}
          dragActive={dragActive}
          updateConfirm={updateConfirm}
          handlePreDelete={handlePreDelete}
        />
      </div>
    </div>
  );
};

AddData.propTypes = {
  showAdd: PropTypes.string,
  tag: PropTypes.object,
  image: PropTypes.object,
  listFeat: PropTypes.object,
  userAdded: PropTypes.string,
  error: PropTypes.string,
  errorImg: PropTypes.string,
  req: PropTypes.bool,
  btnShow: PropTypes.bool,
};

export default AddData;
