import React from "react";

const Gender = ({ ...props }) => {
  return (
    <div className="gender-container">
      <div className="gender-title">Gender</div>
      <label htmlFor="">male</label>
      <input
        name="gender"
        type="radio"
        onChange={(e) => {
          props.setKeyMap(e.target.value);
        }}
        placeholder="Quick Search"
        value="1"
      />
      <label htmlFor="">female</label>
      <input
        name="gender"
        type="radio"
        onChange={(e) => {
          props.setKeyMap(e.target.value);
        }}
        placeholder="Quick Search"
        value="2"
      />
      <label htmlFor="">non binary</label>
      <input
        name="gender"
        type="radio"
        onChange={(e) => {
          props.setKeyMap(e.target.value);
        }}
        placeholder="Quick Search"
        value="3"
      />
      <label htmlFor="">all</label>
      <input
        name="gender"
        type="radio"
        onChange={(e) => {
          props.setKeyMap(e.target.value);
        }}
        placeholder="Quick Search"
        value=""
      />
    </div>
  );
};

export default Gender;
