import React from "react";
import NavBar from "./NavBar.jsx";
import Logo from "./Logo.jsx";
import Top from "./Top.jsx";
import { AuthUser } from "../../context/Auth.jsx";
import LogIn from "../../pages/Login.jsx";

const Header = () => {
  const { isLoggedIn } = AuthUser();
  return (
    <header>
      <div className="logo-container">
        <Logo />
      </div>
      {isLoggedIn && (
        <div className="header-bottom-container">
          <NavBar />
          <Top />
        </div>
      )}
    </header>
  );
};

export default Header;
