import React, { useState } from "react";
import { AuthUser } from "../../../context/Auth";

const Upload = ({ ...props }) => {
  let [prev, setPrev] = useState();
  let [activ, setActiv] = useState();
  const { localIP } = AuthUser();

  let profImage = props.userData?.profileimage;
  let imageName = props.image;
  if (!imageName && !profImage & !prev) {
    activ = 0;
  }

  if (imageName) {
    prev = imageName;
    activ = 2;
  }

  if (profImage && !prev) {
    activ = 1;
  }

  const noImage = "/images/no-photo.png";

  const handleImageError = (e) => {
    e.target.src = noImage;
  };

  //console.log(profImage, imageName);
  return (
    <div className="upload">
      <label htmlFor="file">
        <div
          className="profile-image"
          id={activ !== 1 ? "drag-file-element" : "disabled"}
          onDragEnter={(e) =>
            props.hide !== 1 && props.hide !== 1 && props.handleDrag(e)
          }
          onDragLeave={(e) => props.hide !== 1 && props.handleDrag(e)}
          onDragOver={(e) => props.hide !== 1 && props.handleDrag(e)}
          onDrop={(e) => props.hide !== 1 && props.handleDrop(e)}
        >
          {activ === 0 && (
            <>
              <p className="missing-photo">
                Profile Picture
                <br />
                required !
              </p>
              <span className="material-icons big-person">person</span>
              <span className="material-icons">add_photo_alternate</span>
            </>
          )}
          <span className="image-name">
            {activ === 1 && (
              <img
                src={`http://${localIP}:3001/images/profiles/${profImage}`}
                alt="profile"
                onError={(e) => handleImageError(e)}
              />
            )}
            {activ === 2 && (
              <img
                src={URL.createObjectURL(prev)}
                alt="profile"
                onError={(e) => handleImageError(e)}
              />
            )}
          </span>
        </div>
      </label>
      {props.hide !== 1 && (
        <input
          type="file"
          name="uploadImage"
          onChange={(e) => {
            props.handleImage(e);
            setPrev(e.target.files[0]);
            setActiv(2);
          }}
          id="file"
          pattern="([^\\s]+(\\.(?i)(jpg|png|gif|bmp))$)"
        />
      )}
      <div className="error">{props.errorImg}</div>
    </div>
  );
};

export default Upload;
