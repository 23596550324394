/* eslint-disable array-callback-return */
/* eslint-disable default-case */
import React, { useRef, useState, useEffect } from "react";
import { AuthUser } from "../../context/Auth.jsx";
import {
  FilterSlidersHeight,
  FilterSlidersAge,
  FilterSlidersShoes,
  FilterSlidersClothes,
} from "../users/Sliders.jsx";
import FilterGender from "../users/Gender.jsx";

const Filters = ({ ...props }) => {
  const { userId } = AuthUser();
  // const [isChecked, setIsChecked] = useState(false);
  const [showTags, setShowTags] = useState(false);
  const [keyType, setKeyType] = useState("Tag");
  const [showFilter, setShowFilter] = useState(false);
  const mapName = props.mapName;
  const keyName = useRef("null");
  if (mapName && showTags !== true) {
    setShowTags(true);
  }

  useEffect(() => {}, [mapName, keyType, keyName]);

  /* Clear Search By Input Filters
  const handleClearForm = () => {
    keyName.current.value = "";
    props.setMapName("");
    props.setKeyMap("");
    props.sequ();
    props.clearSliders();
  };
 */
  const handleKeyMap = (e) => {
    //   if (isChecked) {
    //   handleClearForm();
    //   } else {
    props.setKeyMap(e.target.id);
    //  }
  };
  return (
    <div className="find-filter-container">
      <div className="filter-top">
        <div className="main-filters">
          <div className="top-left-filter">
            <div className="left-top">
              <div className="search-bar">
                <label htmlFor=""></label>
                <input
                  id="reset"
                  ref={keyName}
                  type="text"
                  defaultValue={mapName}
                  onChange={(e) => {
                    props.setMapName(e.target.value);
                  }}
                  placeholder="Quick Search"
                />
                <div className="search-input-filter">
                  {showFilter === true ? (
                    <div
                      className="search-dropdown-button"
                      onClick={() => setShowFilter(false)}
                    >
                      {keyType}
                    </div>
                  ) : (
                    <div
                      className="search-dropdown-button after"
                      onClick={() => setShowFilter(true)}
                    >
                      {keyType}
                    </div>
                  )}
                  <div
                    className={
                      showFilter === true
                        ? "search-input-dropdown on"
                        : "search-input-dropdown "
                    }
                  >
                    <div
                      className="search-input-element"
                      id="name"
                      onClick={(e) => {
                        handleKeyMap(e);
                        // setIsChecked(false);
                        setKeyType(e.target.id);
                        setShowFilter(false);
                      }}
                    >
                      Name
                    </div>
                    <div
                      className="search-input-element"
                      id="id"
                      onClick={(e) => {
                        handleKeyMap(e);
                        // setIsChecked(true);
                        setKeyType("ID/Phone");
                        setShowFilter(false);
                      }}
                    >
                      ID/Phone
                    </div>
                    <div
                      className="search-input-element"
                      id="Tag"
                      onClick={(e) => {
                        handleKeyMap(e);
                        //   setIsChecked(true);
                        setKeyType("Tag");
                        setShowFilter(false);
                      }}
                    >
                      Tag
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="left-bottom">&nbsp;</div>
          </div>
          <div
            className="top-right-filter"
            onMouseOut={() => setShowFilter(false)}
          >
            <div className="right-top-container">
              <div className="age-filter">
                <FilterSlidersAge
                  age={props.age}
                  setAge={props.setAge}
                  filterData={props.secondFilterData}
                />
              </div>
              <div className="height-filter">
                <FilterSlidersHeight
                  setHeight={props.setHeight}
                  height={props.height}
                  filterData={props.secondFilterData}
                />
              </div>
              <div className="shoes-filter">
                <FilterSlidersShoes
                  shoe={props.shoe}
                  setShoe={props.setShoe}
                  filterData={props.secondFilterData}
                />
              </div>
              <div className="clothes-filter">
                <FilterSlidersClothes
                  clothes={props.clothes}
                  setClothes={props.setClothes}
                  filterData={props.secondFilterData}
                />
              </div>
              <div className="gender-filter">
                <FilterGender setKeyMap={props.setGender} />
              </div>
            </div>
            <div className="right-bottom-container"></div>
          </div>
        </div>
      </div>
      <div className="filter-bottom">
        <div className="tags-open-container">
          {showTags === false ? (
            <div className="tags-open" onClick={() => setShowTags(true)}>
              Advanced Filter
            </div>
          ) : (
            <div
              className="tags-open active"
              onClick={() => setShowTags(false)}
            >
              Advanced Filter
            </div>
          )}
        </div>
        <div
          className={showTags === true ? "tag-container on" : "tag-container"}
          onMouseOut={() => setShowFilter(false)}
        >
          {props.tagList
            .filter((getOnly) =>
              keyType === "Tag" ? getOnly.includes(mapName) : getOnly
            )
            .sort((a, b) => a - b)
            .map((tags, index) => {
              let tagName = "";
              if (tags.includes("hair")) {
                tagName = "hair";
              } else if (tags.includes("eyes")) {
                tagName = "eyes";
              }
              return (
                <div key={index}>
                  <div
                    className={showTags === true ? "tags-list" : "tags-list on"}
                  >
                    <span
                      id="add"
                      data-extrafeatid={index}
                      data-extraname={tagName}
                      data-feat={tags}
                      data-extra_filter_uid={userId}
                      onClick={(e) => {
                        props.handleTagFilters(e);
                      }}
                    >
                      {tags}
                    </span>
                  </div>
                </div>
              );
            })}
          {props.tagListData
            .sort((a, b) => a - b)
            .map((tag, index) => {
              if (tag !== null) {
                return (
                  <div key={index} className="tags-list set">
                    <span
                      id="remove"
                      data-extrafeatid={tag.extrafeatid}
                      data-extra={tag.extra_filter_name}
                      data-feat={tag.feat}
                      data-extra_filter_uid={userId}
                      onClick={(e) => {
                        props.handleRemoveTag(e);
                      }}
                    >
                      {tag.feat}
                    </span>
                  </div>
                );
              }
            })}
        </div>
      </div>
    </div>
  );
};

export default Filters;
