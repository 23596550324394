import { AuthUser } from "../../../context/Auth.jsx";
import React, { useState } from "react";
import axios from "axios";

const ExtraFeatures = ({ ...props }) => {
  const { authUrl } = AuthUser();
  const [showDelete, setShowDelete] = useState("0");

  /* REMOVE EXTRA FEAT -> API */
  const handleRemoveExtraFeat = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.delete(`${authUrl}/removeExtraFeat`, {
        data: {
          eid: e.target.dataset.id,
          newfeat: e.target.dataset.feat,
        },
      });
      console.log(response.data);
      props.sequ();
    } catch (error) {
      console.log(error);
    }
  };

  /* DELETE USER EXTRA FEATURES FROM DB */
  const handleDeleteFeature = async (e) => {
    e.preventDefault();
    if (e.target.dataset.userid) {
      try {
        const response = await axios.delete(`${authUrl}/featRemove`, {
          data: {
            extrafeatid: e.target.dataset.id,
            fid: e.target.dataset.userid,
          },
        });
        console.log(response.data);
        props.sequ();
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <div className="extra-features">
      <div className="extra-features-container">
        {props.listFeat
          ?.sort((a, b) => (a.feat > b.feat ? 1 : -1))
          .map((extra, index) => {
            return (
              <div className="tags" key={index}>
                <button
                  id={extra.id}
                  data-id={extra.id}
                  data-featid={extra.extrafeatid}
                  data-value={extra.feat}
                  onClick={(e) => {
                    props.handleFormFeat(e);
                  }}
                  title="Add Extra Feats"
                >
                  {extra.feat}
                </button>
                <span
                  className={
                    ("material-symbols-outlined",
                    showDelete === "1" ? "material-symbols-outlined" : "hide")
                  }
                  data-id={extra.id}
                  data-feat={extra.feat}
                  onClick={handleRemoveExtraFeat}
                  title="Remove Extra Feats"
                >
                  close
                </span>
              </div>
            );
          })}
        {props.selectedExtraFeat
          ?.sort((a, b) => (a.feat > b.feat ? 1 : -1))
          .map((extra, index) => {
            return (
              <div className="tags" key={index}>
                <button
                  className="selected"
                  data-id={extra.extrafeatid}
                  data-feat={extra.feat}
                  data-featid={extra.id}
                  data-userid={extra.fid}
                  onClick={(e) => {
                    handleDeleteFeature(e);
                    props.handleFormFeatReset(e);
                  }}
                  title="Delete Extra Feats"
                >
                  {extra.feat}
                </button>
              </div>
            );
          })}
      </div>
      <div className="extra-features-delete-container">
        <span
          className={
            ("material-icons",
            showDelete === "0" ? "material-icons" : "material-icons hide")
          }
          onClick={(e) => setShowDelete("1")}
        >
          settings
        </span>
        <span
          className={
            ("material-icons",
            showDelete === "1" ? "material-icons red" : "material-icons hide")
          }
          onClick={(e) => setShowDelete("0")}
        >
          settings
        </span>
      </div>
    </div>
  );
};

export default ExtraFeatures;
