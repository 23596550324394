import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import social from "../assets/image 2.svg";
import random from "../assets/home_bottom.png";
import { homeTextData } from "../data/homeData";
import { contactFormData } from "../data/contactFormData";
import FormsData from "../utils/contact/FormData.jsx";
import JumpUp from "../components/JumpUp.jsx";
import socialData from "../data/socialData.js";

//import Welcome from "./Welcome";

const Main = () => {
  const [formData, setFormData] = useState({
    fullname: "",
    birthday: "",
    height: "",
    phone: "",
    email: "",
  });
  const { about, work, link, errorMaxFiles, errorMaxSize } = homeTextData;
  const [showForm, setShowForm] = useState(false);
  const [buttonState, setButtonState] = useState(true);
  const [images, setImages] = useState();
  const [imageCount, setImageCount] = useState(0);
  const recaptchaRef = React.useRef();
  const [data, setData] = useState([]);
  const [totalSize, setTotalSize] = useState();
  const [fileMessage, setFileMessage] = useState(``);
  const [rssData, setRssData] = useState([]);
  const [returnMsg, setReturnMsg] = useState([]);
  const onSubmitWithReCAPTCHA = async (e) => {
    e.preventDefault();
    const token = await recaptchaRef.current.executeAsync();
    token.current.reset();
  };
  const [ckFullName, setCkFullName] = useState(null);
  const [ckEmail, setCkEmail] = useState(null);
  const [ckPhone, setCkPhone] = useState(null);
  const [ckBirthday, setCkBirthday] = useState(null);
  const [ckHeight, setCkHeight] = useState(null);
  const [ckImage, setCkImage] = useState(null);
  const [validated, setValidated] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  const ref_about = useRef();
  const ref_work = useRef();

  const handleJumpToAbout = () =>
    ref_about.current.scrollIntoView({ behavior: "smooth" });
  const handleJumpToWork = () =>
    ref_work.current.scrollIntoView({ behavior: "smooth" });

  /* Check To Validate Images*/
  useEffect(() => {
    handleRssFeeds();
  }, []);

  /* LOGIN */

  const handleRssFeeds = async () => {
    await axios.get(process.env.REACT_APP_RSS_API).then((response) => {
      //console.log(response.data.entry);
      setRssData(response.data.entry);
    });
  };

  //console.log(rssData);
  //console.log(images?.length);

  const handleSize = () => {
    const initialValue = 0;
    const sumWithInitial = data?.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      initialValue
    );
    setTotalSize(sumWithInitial);
  };

  const handleForm = (e) => {
    setEmailSent("");
    setReturnMsg("");
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  /* Validate Images */
  const handleValidateImage = (e) => {
    if (e.target.files.length >= 4) {
      setFileMessage("Max 3 files are allowed !");
    } else {
      setImages(e.target.files);
    }

    /*
    setFileMessage("");
    let length = "";
    const maxLength = 1;

    for (const images1 of e.target.files) {
      length = e.target.files.length;
      setData((ret) => [...new Set(ret.concat(images1.size))]);
    }

    if (length > 3) {
      setFileMessage(errorMaxFiles);
    } else if (totalSize > maxLength) {
      setFileMessage(errorMaxSize + `${maxLength} KB`);
    } else {
      if (length === 3) {
        setFileMessage(`Max of ${length} files selected reached !`);
      } else setFileMessage(`${length} files selected !`);
      setImages([...e.target.files]);
    }
    */
  };

  const convertUrl = (url) => {
    url = url.split(":").pop();
    return url;
  };

  const validateFields = (e) => {
    e.preventDefault();
    if (imageCount > 3) {
      setFileMessage("Max 3 files allowed !");
      setButtonState(true);
    } else if (imageCount <= 0) {
      setFileMessage("At least one image is required !");
      setCkImage(null);
    }
    !formData.fullname ? setCkFullName("Missing Name !") : setCkFullName(null);
    !formData.email ? setCkEmail("Email is missing !") : setCkEmail(null);
    !formData.birthday
      ? setCkBirthday("Birth Date is missing !")
      : setCkBirthday(null);
    !formData.height ? setCkHeight("Height is missing !") : setCkHeight(null);
    !formData.phone ? setCkPhone("Phone is missing !") : setCkPhone(null);
    if (
      formData.fullname &&
      formData.email &&
      formData.birthday &&
      formData.height &&
      imageCount <= 3 &&
      formData.phone
    ) {
      if (imageCount !== 0) {
        setValidated(true);
        setEmailSent("");
      }
      setButtonState(false);
    } else {
      setEmailSent("Not all required field are completed !");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validated === true) {
      const url = process.env.REACT_APP_FORM_API;
      const data = new FormData();
      data.append("datas", JSON.stringify(formData));
      if (images) {
        for (let i = 0; i < images.length; i++) {
          data.append("file[]", images[i]);
        }
      }
      try {
        axios.post(url, data).then((dataReturn) => {
          //console.log(dataReturn.data);
          setReturnMsg(dataReturn.data);
          document.getElementById("form-reset").reset();
          setValidated(false);
          setEmailSent("Message sent successfully !");
          setFormData([]);
          setImageCount(0);
          setImages();
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <>
      <div className="login">
        {showForm === true ? (
          <div className={showForm === true && "main_form"}>
            <h1>QUICK JOIN</h1>
            <h4>you can pre-apply here to be part of our database </h4>

            <form
              action="?"
              method="post"
              encType="multipart/form-data"
              onSubmit={(e) => {
                handleSubmit(e);
                setButtonState(true);
              }}
              id="form-reset"
            >
              <FormsData
                fieldsData={contactFormData}
                handleForm={handleForm}
                showForm={showForm}
                ckFullName={ckFullName}
                ckEmail={ckEmail}
                ckPhone={ckPhone}
                ckHeight={ckHeight}
                ckBirthday={ckBirthday}
                ckImage={ckImage}
                validateFields={validateFields}
                onSubmitWithReCAPTCHA={onSubmitWithReCAPTCHA}
              />
              <div className="form-element-files">
                <p>
                  Mandatory pictures : 1 portrait , 1 smile , 1 full body on a
                  clean neutral background
                </p>
                {fileMessage && (
                  <div className="error-files">{fileMessage}</div>
                )}
                <label>
                  Upload
                  <input
                    className="input-upload"
                    type="file"
                    id="files"
                    name="file"
                    accept="image/png, image/jpeg"
                    onChange={(e) => {
                      handleValidateImage(e);
                      setFileMessage("");
                      setImageCount(e.target.files.length);
                    }}
                    multiple
                  />
                </label>
                {imageCount !== 0 && (
                  <div className="selected-files">
                    {imageCount} image selected !
                  </div>
                )}
              </div>

              <div className="form-elements-bottom">
                <ReCAPTCHA
                  sitekey={`${process.env.REACT_APP_CAPTCHA}`}
                  ref={recaptchaRef}
                  onChange={() => setButtonState(false)}
                />
              </div>
              <div className="form-elements-buttons">
                <button
                  className={buttonState === false ? "" : "button-off"}
                  onClick={() => setEmailSent(false)}
                  disabled={buttonState}
                  type="submit"
                >
                  SUBMIT
                </button>
              </div>
              <div className="errorMsg">{returnMsg && returnMsg}</div>
            </form>
          </div>
        ) : (
          <div className="main_form_hide"></div>
        )}
        <div className="nav-buttons">
          <nav>
            <ul>
              <li onClick={() => setShowForm(!showForm)}>QUICK JOIN</li>
              <li onClick={() => handleJumpToAbout()}>ABOUT</li>
              <li onClick={() => handleJumpToWork()}>OUR WORK</li>
            </ul>
          </nav>
        </div>
      </div>
      <div className="main-container-bottom">
        <div className="main-container">
          <div className="main-social-media">
            {socialData
              .filter((filterId) => filterId.id <= 6)
              .map((getData) => (
                <div key={getData.id} className="contact-socials">
                  <a href={getData.socialLink} target={getData.target}>
                    <span className={getData.socialIcon} />
                  </a>
                </div>
              ))}
          </div>
          <div className="main-about">
            <h1 ref={ref_about}>ABOUT US</h1>
            <div className="about-text">
              <p>{about}</p>
              <div className="about-image">
                <img src={random} alt="about"></img>
              </div>
            </div>
            <div className="contact-icons-container"></div>
          </div>
          <div className="main-work">
            <div className="work-top">
              <h1 ref={ref_work}>OUR WORK</h1>
            </div>
            <p>{work}</p>
            <div className="main-youtube">
              {rssData.map((gedFeedData) => {
                return (
                  <div key={gedFeedData.id} className="main-video">
                    <embed
                      src={`https://www.youtube-nocookie.com/embed/${convertUrl(
                        gedFeedData.id
                      )}`}
                      width="350"
                      height="200"
                    />
                  </div>
                );
              })}
              <div className="view-more">
                <a
                  href="https://www.youtube.com/@shinecastingtalentsagency/videos"
                  target="_new"
                >
                  view more
                  <span className="material-icons">double_arrow</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <JumpUp />
    </>
  );
};

export default Main;
