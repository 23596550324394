let length = 0;
export const homeTextData = {
  about: `Shine Casting and Talents Agency, established in 2002 , has been
                a pioneer in the casting industry in Bucharest Romania. As one
                of the first casting agencies in the city, we have been
                dedicated to connecting talented individuals with exciting
                opportunities in the world of TV commercials and movies.At Shine
                Casting, we meticulously organize casting calls to discover and
                select individuals who possess the right skills, look, and
                charisma for various projects. We work closely with production
                companies, directors, and casting directors to ensure the
                perfect fit for each role. We focus on diversity and all our
                talents are handpicked.If you're interested in exploring
                potential collaborations, have any inquiries, or simply want to
                connect with us, please feel free to get in touch.`,
  link: "https://www.youtube.com/@shinecastingtalentsagency",
  work: `To provide you with a glimpse of our diverse portfolio, we will be
              sharing a selection of our work . You can expect to see a range of
              talented individuals showcasing their skills and contributing to
              compelling visual storytelling`,
  errorMaxFiles: `Max. 3 image files allowed !`,
  errorMaxSize: `File size is too large, max. allowed `,
};

export const homeSocialData = {};
