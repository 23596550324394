import React from "react";
import RoutesPath from "../routes/Routes.jsx";
import Header from "../components/mainpage/Header.jsx";
import Footer from "../components/mainpage/Footer.jsx";

const Container = () => {
  return (
    <div className="container">
      <Header />
      <RoutesPath />
      <Footer />
    </div>
  );
};

export default Container;
