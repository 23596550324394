const hairColor = [
  {
    id: "h1",
    haircolor: "black hair",
  },
  {
    id: "h2",
    haircolor: "brown hair",
  },
  {
    id: "h3",
    haircolor: "lightbrown hair",
  },
  {
    id: "h4",
    haircolor: "white hair",
  },
  {
    id: "h5",
    haircolor: "blond hair",
  },
  {
    id: "h6",
    haircolor: "grey hair",
  },
  {
    id: "h7",
    haircolor: "red hair",
  },
  {
    id: "h8",
    haircolor: "ginger hair",
  },
  {
    id: "h9",
    haircolor: "strix and highlights",
  },
];

export default hairColor;
