import React, { useState } from "react";
import axios from "axios";
import { AuthUser } from "../../../context/Auth";

const AddExtraFeatures = ({ ...props }) => {
  const [newTag, setNewTag] = useState([]);
  const [show, setShow] = useState("0");
  //const [showTags, setShowTags] = useState("0");
  const { authUrl, setShowDelete } = AuthUser();

  /* HANDLE REMOVE EXTRAFEATURES FROM DB */
  const handleSubmitExtraFeat = async (e) => {
    e.preventDefault();
    const extraData = {
      ["feat"]: e.target.dataset.feat,
      ["category"]: "extra",
    };
    if (extraData)
      try {
        const response = await axios.post(`${authUrl}/addExtraFeat`, extraData);
        console.log(response.data);
        props.sequ();
      } catch (error) {
        console.log(error.response.data);
      }
  };

  return (
    <div className="add-extra-features">
      <div className="add-extra-features-left">
        <p className="add-extra-features-title">EXTRA FEATURES </p>
        <span
          className={
            ("material-icons icon",
            show === "1" ? "hide" : "material-icons icon")
          }
          onClick={(e) => {
            setShow("1");
          }}
        >
          add
        </span>
        <span
          className={
            ("material-icons icon",
            show === "0" ? "hide" : "material-icons icon")
          }
          onClick={(e) => {
            setShow("0");
          }}
        >
          remove
        </span>

        <div className={show === "0" ? "hide" : "tags"}>
          <input
            type="text"
            onChange={(e) => {
              setNewTag(e.target.value);
            }}
          />
          <button
            type="button"
            key={newTag}
            //data-id={newTag}
            data-feat={newTag}
            onClick={(e) => {
              // props.handleAddExtraFeat(e);
              handleSubmitExtraFeat(e);
              setShow("0");
            }}
          >
            Add New
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddExtraFeatures;
