import React from "react";

const SpecialNotes = ({ ...props }) => {
  return (
    <div className="special-notes">
      SpecialNotes
      <div>
        <textarea
          name="text"
          defaultValue={props.userData?.usertext}
          placeholder={props.userData?.usertext}
          onChange={(e) => props.handleText(e)}
        ></textarea>
      </div>
    </div>
  );
};

export default SpecialNotes;
