/* eslint-disable eqeqeq */
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { AuthUser } from "../../context/Auth.jsx";
import Form from "../../components/users/Form.jsx";

const UpdateData = () => {
  const navigate = useNavigate();
  const userId = useParams();
  let {
    url,
    authUrl,
    defaultStartDate,
    defaultEndDate3,
    defaultEndDate5,
    imgUrl,
    addConfirm,
  } = AuthUser();
  const [confirmed, setConfirmed] = useState(false);
  const [confirmDeleteUser, setConfirmDeleteUser] = useState(false);
  const [dragActive, setDragActive] = useState(false);
  const [error, setError] = useState(null);
  const [errorImg, setErrorImg] = useState(null);
  const [image, setUploadImage] = useState();
  let [images, setImages] = useState([]);
  const [imagesID, setImagesID] = useState();
  const [userText, setUserText] = useState("");
  const [validateForm, setValidateForm] = useState(false);
  const [selectedExtraFeat, setSelectedExtraFeat] = useState([]);
  const [listFeat, setListFeat] = useState([]);
  const [nationality, setNationality] = useState();
  const [userData, setUserData] = useState([]);
  const [filteredUserData, setFilteredUserData] = useState();
  const [imageData, setImageData] = useState();
  let [featData, setFeatData] = useState();
  let [extraFeatData, setExtraFeatData] = useState();
  const [profileNames, setProfileNames] = useState();
  const [profiles, setProfiles] = useState();
  let [addListFeat, setAddListFeat] = useState([]);
  const [featSet, setFeatSet] = useState({
    eyecolor: "",
    ethnicity: "",
    haircolor: "",
    nationality: "",
  });
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    username: "",
    role: "",
    email: "",
    password: "",
    birthyear: "",
    height: "",
    gender: "",
    phone: "",
    active: "",
    startingdate: "",
    endingdate: "",
    exclusive: "",
    shoe: "",
    clothes: "",
    uploadImage: "",
  });
  const [updateConfirm, setUpdateConfirm] = useState();

  useEffect(() => {
    sequ();
  }, []);

  const sequ = async () => {
    try {
      const response = await axios.get(`${url}/seq`);
      if (response) {
        const userExtraData = response.data.users.filter(
          (filter) => filter.id == userId.id
        );
        response.data.users
          .filter((filter) => filter.id == userId.id)
          .map((uData) => {
            setUserData(uData);
          });
        setNationality(response?.data.nat);
        setAddListFeat(response?.data.extra);
        setProfiles(response?.data.prof);
        setProfileNames(response?.data.profname);
        userExtraData?.map((galleries) => {
          setImageData(galleries?.galleries);
        });
        userExtraData?.map((features) => setFeatData(features.features));
        let listId = [];
        userExtraData?.map((features) => {
          let result = features.features.filter((select) => {
            return select.ftype == 4;
          });
          listId = new Set(result?.map((filter) => filter.id));
          setSelectedExtraFeat(result);
          setListFeat(
            response?.data.extra.filter((rem) => !listId.has(rem.id))
          );
          setUpdateConfirm(addConfirm);
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  /* HANDLE DRAG FILE EVENT */
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    setFormData({ ...formData, ["uploadImage"]: e.dataTransfer.files[0] });
  };

  // triggers when file is dropped
  const handleDropMulti = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (!images) {
      setImages(e.dataTransfer.files);
    } else {
      let temp = images;
      images = Array.prototype.slice
        .call(temp)
        .concat(Array.prototype.slice.call(e.dataTransfer?.files));
      setImages(images);
    }
  };

  /* HANDLE REMOVE IMAGE FROM LIST ----- NOT REQUIRED */
  const handlePreDelete = (e) => {
    const imageId = e.target.dataset.id;
    images = images?.filter((returnedImages, index) => index != imageId);
    setImages(images);
  };

  /* HANDLE RESTORE EXTRA FEATURES TAGS ----- NOT REQUIRED */
  const handleFormFeatReset = (e) => {
    /* Get Data */
    let dataFeat = [
      {
        feat: e.target.dataset.feat,
        id: e.target.dataset.featid,
      },
    ];
    let listId = new Set(dataFeat?.map((get) => get.id));
    /* Filter Data - Set Unselected Data */
    dataFeat = listFeat.concat(dataFeat);
    setListFeat(dataFeat);
    /* Filter Data - Set Selected Data */
    let filtered = selectedExtraFeat.filter((check) => !listId.has(check.id));
    setSelectedExtraFeat(filtered);
  };

  /* Handle Add New Extra Features TAGS ----- NOT REQUIRED */
  const handleAddExtraFeat = (e) => {
    e.preventDefault();
    // activeFeat = activeFeat.
    let dataAdd = e.target.dataset;

    /* Filter Duplicate Selection for TAGS */
    let filtered = selectedExtraFeat.find((remove) =>
      remove.feat.includes(dataAdd.feat)
    );

    let filterFeat = listFeat.find((remove) => remove.id === dataAdd.id);

    /* Prevent Submiting Duplicates TAGS */
    if (dataAdd.id && !filtered !== false && !filterFeat) {
      const dataFeat = selectedExtraFeat.concat(dataAdd);
      setSelectedExtraFeat(dataFeat);
    }
  };

  /* Handle Extra Features Data  ----- NOT REQUIRED */
  const handleFormFeat = (e) => {
    e.preventDefault();

    /* Proccess TAGS Data */
    let dataExtraFeat = [
      {
        ["feat"]: e.target.dataset.value,
        ["id"]: e.target.dataset.id,
        ["extrafeatid"]: e.target.dataset.id,
      },
    ];
    /* Create list ID */
    let listId = new Set(dataExtraFeat?.map((get) => get.id));
    let checkId = selectedExtraFeat.filter((check) => listId.has(check.id));
    /* Set Selected And Unselected Lists */
    if (checkId) {
      setSelectedExtraFeat((selectedExtraFeat) => [
        ...new Set(selectedExtraFeat.concat(dataExtraFeat)),
      ]);
      setListFeat(
        listFeat.filter((remove) => remove.id != e.target.dataset.id)
      );
    }
  };

  /* Handle Features Data  ----- NOT REQUIRED */
  const handleFormFeatSet = (e) => {
    setFeatSet({
      ...featSet,
      [e.fname]: e.value,
    });
  };

  /* Handle Special Notes Data  ----- NOT REQUIRED */
  const handleText = (e) => {
    setUserText(e.target.value);
  };

  /* Handle Snapshots Images ----- NOT REQUIRED */
  const handleGender = (e) => {
    setFormData({
      ...formData,
      [e.name]: e.value,
    });
  };

  /* Handle Profile Image ----- REQUIRED */
  const handleImage = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.files[0] });
  };

  /* Handle Snapshots Images ----- NOT REQUIRED */
  const handleImages = (e) => {
    // setImages(e.target.files);
    if (!images && e.target.files) {
      setImages(e.target.files);
    } else if (!images && e.dataTransfer?.files) {
      setImages(e.dataTransfer?.files);
    } else if (images && e.target.files) {
      let temp = images;
      images = Array.prototype.slice
        .call(temp)
        .concat(Array.prototype.slice.call(e.target?.files));
      setImages(images);
    } else if (images && e.dataTransfer?.files) {
      let temp = images;
      images = Array.prototype.slice
        .call(temp)
        .concat(Array.prototype.slice.call(e.dataTransfer?.files));
      setImages(images);
    }
  };

  /* Handle ID Image ----- NOT REQUIRED */
  const handleImagesID = (e) => {
    setImagesID(e.target.files);
  };

  /* Handle Clothes Size ----- NOT REQUIRED */
  const handleClothes = (e) => {
    setFormData({
      ...formData,
      [e.target.dataset.name]: e.target.dataset.clothes,
    });
  };

  /* Handle Exclusive Data ----- NOT REQUIRED */
  const handleExclusive = (e) => {
    setFormData({
      ...formData,
      [e.currentTarget.dataset.name]: e.currentTarget.dataset.startingdate,
      [e.currentTarget.dataset.name]: e.currentTarget.dataset.endingdate,
    });
  };

  /* Handle Input Fields ----- REQUIRED */
  const handleForm = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  /* Handle Check Data Before Submit */
  const handleValidate = () => {
    /*   if (
      formData?.uploadImage &&
      formData?.firstname &&
      formData?.lastname &&
      formData?.birthyear &&
      formData?.height &&
      //  formData?.gender &&
      formData?.phone
    ) {*/
    setValidateForm(true);
    /*} else {
      console.log("Missing Required Form Data", validateForm);
    }*/
  };

  /* SUBMIT FORM / ADD NEW USER -> API */
  const handleAddUser = async (e) => {
    e.preventDefault();
    /* Check If Form Data is Validated */
    if (validateForm === true) {
      console.log("Form Data Validated !", validateForm);
      const addUpdateData = new FormData();
      const age = new Date().getFullYear() - formData.birthyear;
      formData.username && addUpdateData.append("username", formData.username);
      formData.email && addUpdateData.append("email", formData.email);
      confirmed === "on" && addUpdateData.append("password", formData.password);
      formData.role && addUpdateData.append("role", formData.role);
      formData.firstname &&
        addUpdateData.append("firstname", formData.firstname);
      formData.lastname && addUpdateData.append("lastname", formData.lastname);
      formData.birthyear &&
        addUpdateData.append("birthyear", formData.birthyear);
      formData.birthyear && addUpdateData.append("age", age);
      formData.height && addUpdateData.append("height", formData.height);
      formData.gender && addUpdateData.append("gender", formData.gender);
      formData.shoe && addUpdateData.append("shoe", formData.shoe);
      formData.clothes && addUpdateData.append("clothes", formData.clothes);
      formData.phone && addUpdateData.append("phone", formData.phone);
      userText && addUpdateData.append("usertext", userText);
      /*--*/
      featSet.length !== 58 &&
        addUpdateData.append("feat", JSON.stringify(featSet));
      /*--*/
      if (selectedExtraFeat)
        addUpdateData.append("extrafeat", JSON.stringify(selectedExtraFeat));
      /*--*/
      if (!formData.startingdate && formData.endingdate) {
        addUpdateData.append("startingdate", defaultStartDate);
      } else {
        formData.startingdate &&
          addUpdateData.append("startingdate", formData.startingdate);
      }
      /*--*/
      formData.endingdate &&
        addUpdateData.append("endingdate", formData.endingdate);
      /*--*/
      formData.active && addUpdateData.append("active", formData.active);
      /*--*/
      if (formData.uploadImage) {
        addUpdateData.append("uploadImage", formData.uploadImage);
      }
      /*--*/
      if (images) {
        Object.values(images).forEach((file) => {
          addUpdateData.append("uploadImages", file);
        });
      }
      /*--*/
      if (imagesID) {
        Object.values(imagesID).forEach((file) => {
          addUpdateData.append("uploadImagesID", file);
          addUpdateData.append("imagesID", file.name);
        });
      }
      /*--*/
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      /*--*/
      try {
        await axios
          .put(`${authUrl}/seqUpdate/${userId.id}`, addUpdateData, config)
          .then((response) => {
            console.log(response?.data);
            setUpdateConfirm("Update Successfully !");
            setImages([]);
            sequ();
          });
      } catch (error) {
        console.log(error);
      }
    } else {
      console.log("Missing Required Form Data", validateForm);
      setUpdateConfirm("Update FAIL !");
    }
  };

  /* DELETE USER/FEATURES/GALLERIES -> SEND DELETE TO API */
  const handleConfirmDeleteUser = async () => {
    setConfirmDeleteUser(true);
  };

  /* DELETE USER/FEATURES/GALLERIES -> SEND DELETE TO API */
  const handleDeleteUser = async () => {
    try {
      const response = await axios.delete(`${authUrl}/delete/${userId.id}`, {
        data: { imageData },
      });
      response && navigate("/dashboard");
      console.log(response.data);
    } catch (error) {
      console.error(error.message);
    }
  };

  const [userFilteredData, setUserFilteredData] = useState();

  const filterData = async () => {
    const filteredData = userData?.filter(
      (filterUser) => filterUser.id == `${userId}`
    );
    filteredData?.map((galleries) => setImageData(galleries.galleries));
    filteredData?.map((features) => setFeatData(features.features));
    filteredData?.map((filteredData) => setUserFilteredData(filteredData));
  };

  /* DELETE IMAGE BY GID AND USERID -> SEND DELETE API */
  const handleImgDelete = async (e) => {
    try {
      await axios
        .delete(`${authUrl}/deleteImg`, {
          data: {
            gid: e.target.id,
            id: e.target.dataset.id,
            image: e.target.dataset.file,
          },
        })
        .then((response) => {
          console.log(response.data);
          sequ();
        });
    } catch (response) {
      console.log(response.response.data.error);
      sequ();
    }
  };

  /* SET PROFILE IMAGE -> SEND UPDATE/PUT TO API */
  const handleProfileImage = async (e) => {
    console.log(e.target.dataset);
    e.preventDefault();
    try {
      await axios
        .put(`${authUrl}/profileSet/${userId}`, {
          id: userId.id,
          fileName: e.target.dataset.file,
        })
        .then((response) => {
          console.log(response.data);
          sequ();
        });
    } catch (response) {
      console.log(response.response.data.error);
    }
  };

  /* DELETE PROFILE IMAGE -> SEND DELETE TO API */
  const handleDeleteProfileImage = async (e) => {
    e.preventDefault();
    const pid = e.target.dataset.id;
    try {
      const response = await axios.put(`${authUrl}/profileRemove/${pid}`);
      console.log(response.data);
      sequ(0);
      filterData();
    } catch (response) {
      console.log(response);
    }
  };

  /**
   * @param { Functions As Props } functions : handleShowAdd,handleSubmitExtraFeat,handleAddExtraFeat,
   * removeTag,handleAddUser,handleForm,handleImage,handleTag,handleFormFeat,handleShowAdd,
   * handleAddExtraFeat, handleSubmitExtraFeat, handleRemoveExtraFeat
   * @param { Object } tag
   * @param { Object } image
   * @param { Object } listFeat
   * @param { String } userAdded
   * @param { String } error
   * @param { String } errorImg
   * @param { Boolean } req
   * @param { Boolean } btnShow
   * @param { Boolean } showAdd
   */

  /* SET FORM */
  return (
    <div className="add-data-container">
      <div className="update-data-wrapper">
        <div className="delete-user-container">
          <span
            className="material-icons del"
            onClick={() => setConfirmDeleteUser(true)}
            title="Delete User From DataBase"
          >
            delete_forever
          </span>
        </div>
        <div
          className={
            confirmDeleteUser === true ? "confirm-delete-container" : "hide"
          }
        >
          <button
            className="cancel-delete-user"
            onClick={() => setConfirmDeleteUser(false)}
          >
            Cancel
          </button>
          <button
            className="confirm-delete-user"
            onClick={() => handleDeleteUser()}
          >
            Delete
          </button>
        </div>
        <Form
          handleForm={handleForm}
          handleAddUser={handleAddUser}
          handleImage={handleImage}
          handleImages={handleImages}
          handleImagesID={handleImagesID}
          handleImgDelete={handleImgDelete}
          handleProfileImage={handleProfileImage}
          handleText={handleText}
          handleClothes={handleClothes}
          handleExclusive={handleExclusive}
          handleFormFeat={handleFormFeat}
          handleFormFeatSet={handleFormFeatSet}
          handleGender={handleGender}
          buttonName={"SUBMIT"}
          imageName={formData.uploadImage.name}
          images={images}
          id={userId.id}
          defaultEndDate3={defaultEndDate3}
          defaultEndDate5={defaultEndDate5}
          defaultStartDate={defaultStartDate}
          req={true}
          error={error}
          image={image}
          errorImg={errorImg}
          btnShow={false}
          setUpdate={true}
          validateForm={validateForm}
          handleValidate={handleValidate}
          handleFormFeatReset={handleFormFeatReset}
          listFeat={listFeat}
          setListFeat={setListFeat}
          selectedExtraFeat={selectedExtraFeat}
          setSelectedExtraFeat={setSelectedExtraFeat}
          extraFeatData={extraFeatData}
          handleAddExtraFeat={handleAddExtraFeat}
          sequ={sequ}
          nationality={nationality}
          handleDrag={handleDrag}
          handleDrop={handleDrop}
          handleDropMulti={handleDropMulti}
          setDragActive={setDragActive}
          dragActive={dragActive}
          userData={userData}
          featData={featData}
          imageData={imageData}
          updateConfirm={updateConfirm}
          setUpdateConfirm={setUpdateConfirm}
          handlePreDelete={handlePreDelete}
        />
      </div>
    </div>
  );
};

UpdateData.propTypes = {
  showAdd: PropTypes.string,
  tag: PropTypes.object,
  image: PropTypes.object,
  listFeat: PropTypes.object,
  userAdded: PropTypes.string,
  error: PropTypes.string,
  errorImg: PropTypes.string,
  req: PropTypes.bool,
  btnShow: PropTypes.bool,
};

export default UpdateData;
