import { configureStore } from "@reduxjs/toolkit";
import authUser from "./auth.js";
import addUser from "./adduser.js";
import updateUser from "./updateuser.js";

const store = configureStore({
  reducer: {
    auth: authUser,
    adduser: addUser,
    updateuser: updateUser,
  },
});

export default store;
