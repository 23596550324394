import React from "react";
import LogoImage from "../../assets/shine-logo.svg";

const Logo = () => {
  return (
    <div className="logo">
      <img src={LogoImage} alt="logo" />
    </div>
  );
};

export default Logo;
