import React from "react";
import { NavLink } from "react-router-dom";
//import { AuthUser } from "../../context/Auth.jsx";

const NavBar = () => {
  /*const { sequ } = AuthUser();

  const handleRedirect = () => {
    sequ(0);
  };
*/
  return (
    <nav>
      <ul>
        <li className="first">
          <NavLink
            to={"/dashboard/users/find"}
            /* onClick={() => handleRedirect()}*/
          >
            Database
          </NavLink>
        </li>
        <li>
          <NavLink to={"/dashboard/users/add"}>+ Add New Talent</NavLink>
        </li>
        <li>
          <NavLink to={"/dashboard/users/profiles"}>Shortlists</NavLink>
        </li>
        <li>
          <NavLink to={"/dashboard/users/projects"}>Projects</NavLink>
        </li>
        <li className="last">
          <NavLink to={"/dashboard/users/clients"}>Clients</NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default NavBar;
