import { useState, useEffect } from "react";

const JumpUp = () => {
  const [showTopBtn, setShowTopBtn] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 100) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div>
      {showTopBtn && (
        <span
          className="material-symbols-outlined jump-top"
          onClick={() => goToTop()}
        >
          arrow_upward
        </span>
      )}
    </div>
  );
};

export default JumpUp;
