import { createContext, useContext } from "react";
//import localData from "../data/userData.js";
import React, { useState, useEffect } from "react";
//import { useNavigate } from "react-router";
import axios from "axios";

/* Create Authority Context */
export const AuthUserContext = createContext();
export const AuthUser = () => {
  return useContext(AuthUserContext);
};

export const AuthContextProvider = ({ children }) => {
  /* Set API Send Routes */
  //  const navigate = useNavigate();
  const localIP = "localhost";
  const url = `http://${localIP}:3001/user`;
  const authUrl = `http://${localIP}:3001/auth`;
  const imgUrl = `http://${localIP}:3001/images/profiles`;
  const imgsIDUrl = `http://${localIP}:3001/images/ids`;
  const imgsUrl = `http://${localIP}:3001/images/talents/`;
  let [userData, setUserData] = useState();
  const [listFeat, setListFeat] = useState([]);
  const [filteredUserData, setFilteredUserData] = useState();
  const [imageData, setImageData] = useState();
  const [featData, setFeatData] = useState();
  const [profileNames, setProfileNames] = useState();
  const [profiles, setProfiles] = useState();
  const [nationality, setNationality] = useState();
  const [addConfirm, setAddConfirm] = useState();

  /*
  useEffect(() => {
    fixData();
  }, []);

  const fixData = async () => {
    try {
      const response = await axios.put(`${authUrl}/fix`);
      console.log(response);
    } catch (error) {}
  };
*/

  /* SET USER STORAGE  */
  localStorage.removeItem("userData");
  const cuid = localStorage.getItem("user");
  const token = localStorage.getItem("token");
  const session = sessionStorage.getItem("session");
  const upId = localStorage.getItem("userUpdate");
  const rupId = JSON.parse(upId);
  const uid = JSON.parse(cuid);
  const userId = uid?.id;
  const userRole = uid?.role;
  const username = uid?.username;
  const email = uid?.email;
  const isLoggedIn = JSON.parse(localStorage.getItem("user"));

  /* HANDLE DATES */
  const date = new Date();
  const timeselect = `${date.getFullYear()}${date.getMonth()}${date.getDate()}${date.getHours()}`;
  const endDay =
    `${date.getDate()}` <= 9 ? `0${date.getDate()}` : `${date.getDate()}`;

  const defaultStartDate = `${date.getFullYear()}-${
    date.getMonth() <= 10 && `${"0"}` + (date.getMonth() + 1)
  }-${"01"}`;

  /* Set 3 Years Date default */
  date.setFullYear(date.getFullYear() + 3);
  const defaultEndDate3 = `${date.getFullYear()}-${
    date.getMonth() <= 10 && `${"0"}` + (date.getMonth() + 1)
  }-${endDay}`;

  /* Set 5 Years Date default */
  date.setFullYear(date.getFullYear() + 2);
  const defaultEndDate5 = `${date.getFullYear()}-${
    date.getMonth() <= 10 && `${"0"}` + (date.getMonth() + 1)
  }-${endDay}`;

  /*******************************************************************
   * EXTRA FEATURES **************************************************
   *******************************************************************/

  /* Set Context Variables */
  const authValues = {
    userId,
    userRole,
    url,
    authUrl,
    username,
    email,
    imgUrl,
    imgsIDUrl,
    imgsUrl,
    localIP,
    isLoggedIn,
  };

  return (
    <AuthUserContext.Provider value={authValues}>
      {children}
    </AuthUserContext.Provider>
  );
};
