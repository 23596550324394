export const contactFormData = [
  {
    id: 1,
    label: "Name",
    fieldName: "fullname",
    type: "text",
    placeholder: "Name",
    isRequired: true,
    classType: "form-element-name",
    error: "Missing Name !",
  },
  {
    id: 2,
    label: "Date Of Birth",
    fieldName: "birthday",
    type: "date",
    placeholder: "Birth Date",
    isRequired: true,
    classType: "form-elements",
    error: "Missing Birth Date !",
  },
  {
    id: 3,
    label: "Height",
    fieldName: "height",
    type: "number",
    placeholder: "Height",
    isRequired: true,
    classType: "form-elements",
    error: "Missing Height !",
  },
  {
    id: 4,
    label: "Phone Number",
    fieldName: "phone",
    type: "number",
    placeholder: "Phone Number",
    isRequired: true,
    classType: "form-elements",
    error: "Missing Phone !",
  },
  {
    id: 5,
    label: "Email",
    fieldName: "email",
    type: "email",
    placeholder: "Email",
    isRequired: true,
    classType: "form-elements last",
    error: "Missing Email !",
  },
];
