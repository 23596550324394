import React, { useState } from "react";
import Upload from "./form/ProfileUpload.jsx";
import MultiUpload from "./form/GalleryUploads.jsx";
import Exclusive from "./form/Exclusive.jsx";
import SizeSelector from "./form/SizeSelector.jsx";
import Features from "./form/Features.jsx";
import ExtraFeatures from "./form/ExtraFeatures.jsx";
import AddExtraFeatures from "./form/AddExtraFeatures.jsx";
import SpecialNotes from "./form/SpecialNotes.jsx";

const Form = ({ ...props }) => {
  let userData = props.userData;
  const [showTags, setShowTags] = useState("1");

  return (
    <form className="form" onSubmit={props.handleAddUser} autoComplete="off">
      <div className="container-start">
        <div className="container-id">
          ID : {props.id} <p className="updated">{props.updateConfirm}</p>
        </div>
        <div className="container-active">Active</div>
      </div>
      <div className="container-top">
        <div className="inputs-fields-container">
          <div className="input-fields-container">
            <div className="form-set">
              <label htmlFor=""></label>
              <input
                autoComplete="off"
                type="text"
                name="lastname"
                id="lastname"
                defaultValue={userData?.lastname}
                onChange={props.handleForm}
                placeholder="Firstname"
              />
            </div>
            <div className="form-set">
              <label htmlFor=""></label>
              <input
                autoComplete="off"
                type="text"
                name="firstname"
                id="firstname"
                defaultValue={userData?.firstname}
                onChange={props.handleForm}
                required
                placeholder="Surname*"
              />
            </div>
            <div className="form-set hide">
              <label htmlFor=""></label>
              <input
                autoComplete="off"
                type="text"
                name="username"
                id="username"
                defaultValue={userData?.username}
                onChange={props.handleForm}
                placeholder="Username... default: none"
              />
            </div>
            <div className="form-set hide">
              <label htmlFor=""></label>
              <input
                autoComplete="off"
                type="text"
                name="role"
                id="role"
                defaultValue={userData?.role}
                onChange={props.handleForm}
                placeholder="Username... default: user"
              />
            </div>
            <div className="form-set">
              <label htmlFor=""></label>
              <input
                autoComplete="off"
                type="email"
                name="email"
                id="email"
                defaultValue={userData?.email}
                onChange={props.handleForm}
                placeholder="@Email"
                pattern="^[a-zA-Z0-9!#$%&amp;'*+\/=?^_`\{\|\}~.\-]+@[a-zA-Z0-9\-]+(\.[a-zA-Z0-9\-]+)*$"
              />
            </div>
            <div className="form-set">
              <label htmlFor=""></label>
              <input
                autoComplete="off"
                type="number"
                name="phone"
                id="phone"
                defaultValue={userData?.phone}
                onChange={props.handleForm}
                placeholder="Phone* (+40 xxx xxx xxx)"
                required
              />
            </div>
            <div className="form-set">
              <label htmlFor=""></label>
              <input
                autoComplete="off"
                onWheel={(e) => e.target.blur()}
                type="number"
                min="1940"
                max="2013"
                step="1"
                name="birthyear"
                id="year"
                defaultValue={userData?.birthyear}
                onChange={props.handleForm}
                placeholder="Birth Year*"
                required
              />
            </div>
            <div className="form-set hide">
              <label htmlFor=""></label>
              <input
                autoComplete="off"
                type="password"
                name="password"
                id="password"
                defaultValue={userData?.password}
                onChange={props.handleForm}
                placeholder="Password... default: 111111"
              />
            </div>
            <div className="form-set hide">
              <label htmlFor=""></label>
              <input
                autoComplete="off"
                type="password"
                name="password"
                id="repassword"
                defaultValue={userData?.password}
                onChange={props.handleForm}
                placeholder="Password... default: 111111"
              />
            </div>
            <div className="form-set hide">
              <label>
                Confirm Password Change
                <input
                  autoComplete="off"
                  type="checkbox"
                  onChange={(e) => props.setConfirmed(e.target.value)}
                />
              </label>
            </div>
            <div className="form-set">
              <label htmlFor=""></label>
              <input
                autoComplete="off"
                onWheel={(e) => e.target.blur()}
                type="number"
                min="50"
                max="220"
                list="heights"
                name="height"
                id="height"
                defaultValue={userData?.height}
                onChange={props.handleForm}
                placeholder="Height*(CM)"
                required
              />
              <datalist id="heights">
                <option value="150" />
                <option value="170" />
                <option value="175" />
                <option value="180" />
                <option value="185" />
                <option value="200" />
              </datalist>
            </div>
            <div className="form-set">
              <label htmlFor=""></label>
              <input
                autoComplete="off"
                onWheel={(e) => e.target.blur()}
                type="number"
                name="shoe"
                id="ssize"
                min="30"
                max="48"
                list="shoe-size"
                defaultValue={userData?.shoe}
                onChange={props.handleForm}
                placeholder="Shoe Size(EU)"
              />
              <datalist id="shoe-size">
                <option value="32" />
                <option value="34" />
                <option value="36" />
                <option value="38" />
                <option value="40" />
                <option value="41" />
                <option value="42" />
                <option value="43" />
                <option value="44" />
                <option value="46" />
              </datalist>
            </div>
          </div>
        </div>
        <div className="profile-photo-container">
          {props.setUpdate === true && (
            <div className="form-set">
              {/*"PROFILE PICTURE----------"*/}
              <Upload
                errorImg={props.errorImg}
                required={props.req}
                handleImage={props.handleImage}
                imageName={props.imageName}
                handleDrag={props.handleDrag}
                handleDrop={props.handleDrop}
                setDragActive={props.setDragActive}
                dragActive={props.dragActive}
                images={props.images}
                image={props.image}
                userData={props.userData}
                hide={props.hide}
              />
            </div>
          )}
        </div>
        <div className="dropdowns-container">
          <div className="form-set">
            {/*"FEATURES----------"*/}
            <Features
              handleFormFeat={props.handleFormFeat}
              handleFormFeatSet={props.handleFormFeatSet}
              handleGender={props.handleGender}
              handleNationality={props.handleNationality}
              sequ={props.sequ}
              nationality={props.nationality}
              userData={userData}
              featData={props.featData}
            />
          </div>
        </div>
      </div>
      <div className="container-middle">
        <div className="dress-container">
          <div className="form-set">
            {/*"DRESS SIZE----------"*/}
            <SizeSelector
              handleClothes={props.handleClothes}
              clothes={props.clothes}
              userData={userData}
            />
          </div>
        </div>
        <div className="exclusive-user-container">
          <div className="form-set">
            {/*"EXCLUSIVE----------"*/}
            <Exclusive
              defaultStartDate={props.defaultStartDate}
              defaultEndDate3={props.defaultEndDate3}
              defaultEndDate5={props.defaultEndDate5}
              handleExclusive={props.handleExclusive}
              listFeat={props.listFeat}
              setListFeat={props.setListFeat}
              userData={userData}
            />
          </div>
        </div>
        <div className="snapshots-container">
          <div className="form-set">
            {/*"GALLERIES UPLOAD----------"*/}
            <MultiUpload
              errorImg={props.errorImg}
              handleImages={props.handleImages}
              images={props.images}
              handleDrag={props.handleDrag}
              handleDropMulti={props.handleDropMulti}
              setDragActive={props.setDragActive}
              dragActive={props.dragActive}
              userData={userData}
              imageData={props.imageData}
              handleImgDelete={props.handleImgDelete}
              setUpdateConfirm={props.setUpdateConfirm}
              handleProfileImage={props.handleProfileImage}
              handlePreDelete={props.handlePreDelete}
              setDeleteImgConfirmed={props.setDeleteImgConfirmed}
            />
          </div>
        </div>
        <div className="video-container hide">video</div>
      </div>
      <div className="container-bottom">
        <div className="extra-user-features-container">
          <AddExtraFeatures
            handleAddExtraFeat={props.handleAddExtraFeat}
            setShowTags={setShowTags}
            userData={userData}
            sequ={props.sequ}
          />
        </div>
        <div className="under-line">underline 1</div>
        <div className="add-extra-features-right">
          <span
            className={
              ("material-icons icon",
              showTags === "0" ? "material-icons icon" : "hide")
            }
            onClick={(e) => {
              setShowTags("1");
            }}
          >
            expand
          </span>
          <span
            className={
              ("material-icons icon",
              showTags === "1" ? "material-icons icon" : "hide")
            }
            onClick={(e) => {
              setShowTags("0");
            }}
          >
            vertical_align_top
          </span>
        </div>
        <div
          className={
            ("extra-features-tags-container",
            showTags === "1" ? "extra-features-tags-container" : "hide")
          }
        >
          {/*"EXTRAFEATURES----------"*/}
          <ExtraFeatures
            handleExtraTags={props.handleExtraTags}
            handleFormFeat={props.handleFormFeat}
            handleFormFeatReset={props.handleFormFeatReset}
            listFeat={props.listFeat}
            setListFeat={props.setListFeat}
            selectedExtraFeat={props.selectedExtraFeat}
            setSelectedExtraFeat={props.setSelectedExtraFeat}
            showTags={showTags}
            sequ={props.sequ}
            featData={props.featData}
            extraFeatData={props.extraFeatData}
          />
        </div>

        <div className="under-line">underline 2</div>
        <div className="special-notes-submit-container">
          <div className="special-notes-container">
            <SpecialNotes handleText={props.handleText} userData={userData} />
          </div>
          <div className="submit-container">
            <div className="submit-button-container">
              <div className="form-set">
                {/*"SUBMIT----------"*/}
                <button
                  className="submit"
                  type="submit"
                  onClick={(e) => {
                    props.handleValidate(e);
                  }}
                >
                  {props.buttonName}
                </button>
              </div>
            </div>
            <div className="info-container">
              <p>* MANDATORY FIELDS</p>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default Form;
