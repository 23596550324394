import React, { useState } from "react";
import { AuthUser } from "../../../context/Auth.jsx";

const Upload = ({ ...props }) => {
  let [prev, setPrev] = useState();
  let { imgsUrl } = AuthUser();
  const [showConfirm, setShowConfirm] = useState(false);

  if (!prev && props?.images) {
    prev = props?.images;
  }

  let errorImages = "";

  const noImage = "/images/no-photo.png";

  const handleImageError = (e) => {
    e.target.src = noImage;
  };

  if (props.images) {
    const imagesName = Object.entries(props.images);
    if (imagesName.length > 12) {
      errorImages = "You cannot add more than 12 files";
    }
  }
  /* if (errorImages) {
    props.images = "";
  }
*/

  return (
    <div className="uploads">
      <label htmlFor="files">
        <div className="galleries-images">
          <div
            className="no-images"
            id="drag-file-element"
            onChange={(e) => props.handleImages(e)}
            onDragEnter={(e) => props.handleDrag(e)}
            onDragLeave={(e) => props.handleDrag(e)}
            onDragOver={(e) => props.handleDrag(e)}
            onDrop={(e) => props.handleDropMulti(e)}
          >
            <p>SnapShots</p>
            <span className="material-icons">add_photo_alternate</span>
          </div>
          <input
            type="file"
            onChange={(e) => props.handleImages(e)}
            name="uploadImages"
            id="files"
            multiple="multiple"
            pattern="([^\\s]+(\\.(?i)(jpg|png|gif|bmp))$)"
          />
        </div>
      </label>
      <p className="limit-images">{errorImages}</p>
      <div className="galleries-container">
        {props?.images && (
          <div className="image-name-container prev">
            {Object.entries(props.images).map(([res, tes]) => {
              return (
                <img
                  onDoubleClick={(e) => {
                    // setShowConfirm(1);
                    props.handlePreDelete(e);
                  }}
                  data-name={tes.name}
                  data-id={res}
                  key={res.id + tes}
                  src={URL.createObjectURL(tes)}
                  alt="profile"
                  onClick={(e) => {
                    URL.revokeObjectURL(tes);
                  }}
                  onError={(e) => handleImageError(e)}
                />
              );
            })}
          </div>
        )}
        {props?.imageData && (
          <div className="image-name-container">
            {props.imageData?.map((res, index) => {
              return (
                <div
                  key={res.id}
                  className={showConfirm === true ? "delete" : ""}
                >
                  <img
                    id={res.gid}
                    data-id={res.id}
                    data-file={res.image}
                    src={imgsUrl + res.image}
                    alt="profile"
                    title="Double Click To Delete"
                    onDoubleClick={(e) => {
                      setShowConfirm(!showConfirm);
                    }}
                    onClick={(e) => {
                      props.handleProfileImage(e);
                    }}
                    onError={(e) => handleImageError(e)}
                  />
                  {showConfirm === true && (
                    <div
                      className="delete-image-yes"
                      title="Double Click to Confirm"
                      key={res.id}
                      id={res.gid}
                      data-id={res.id}
                      data-file={res.image}
                      src={imgsUrl + res.image}
                      alt="profile"
                      onDoubleClick={(e) => {
                        setShowConfirm(!showConfirm);
                        props.handleImgDelete(e);
                        props.handlePreDelete(e);
                        props.setUpdateConfirm("Image Deleted");
                      }}
                    />
                  )}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default Upload;
