import Slider from "@mui/material/Slider";
import { useState } from "react";

export const FilterSlidersHeight = ({ ...props }) => {
  //const [height, setHeight] = useState([0, 220]);
  const heightCursorMinDistance = 1;
  /* CURSOR SET - FILTER HEIGHT */

  const handleHeightChange = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }
    if (activeThumb === 0) {
      props.setHeight([
        Math.min(newValue[0], props.height[1] - heightCursorMinDistance),
        props.height[1],
      ]);
    } else {
      props.setHeight([
        props.height[0],
        Math.max(newValue[1], props.height[0] + heightCursorMinDistance),
      ]);
    }
    props.filterData();
  };

  return (
    <div className="slider-container">
      <div className="height-container">
        <div className="height-title">
          Height
          <div className="sliders-values">
            <p>
              {props.height[0]} - {props.height[1]}
            </p>
          </div>
        </div>
        <div className="height-cursor">
          {/*<p className="startvalue">{props.height[0]}</p>*/}
          <Slider
            className="slider-content "
            getAriaLabel={() => "Minimum distance"}
            value={props.height}
            min={0}
            max={220}
            onChange={handleHeightChange}
            valueLabelDisplay="auto"
            disableSwap
          />
          {/*  <p className="startvalue">{props.height[1]}</p>*/}
        </div>
      </div>
    </div>
  );
};

export const FilterSlidersAge = ({ ...props }) => {
  //const [age, setAge] = useState([0, 100]);
  const ageCursorMinDistance = 1;

  /* CURSOR SET - FILTER AGE */
  const handleAgeChange = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }
    if (activeThumb === 0) {
      props.setAge([
        Math.min(newValue[0], props.age[1] - ageCursorMinDistance),
        props.age[1],
      ]);
    } else {
      props.setAge([
        props.age[0],
        Math.max(newValue[1], props.age[0] + ageCursorMinDistance),
      ]);
    }
    props.filterData();
  };

  return (
    <div className="slider-container">
      <div className="age-container">
        <div className="age-title">
          Age
          <div className="sliders-values">
            <p>
              {props.age[0]} - {props.age[1]}
            </p>
          </div>
        </div>
        <div className="age-cursor">
          {/* <p className="startvalue">{props.age[0]}</p> */}
          <Slider
            className="slider-content"
            getAriaLabel={() => "Minimum distance"}
            value={props.age}
            min={0}
            max={100}
            step={1}
            onChange={handleAgeChange}
            valueLabelDisplay="auto"
            disableSwap
          />
          {/* <p className="startvalue">{props.age[1]}</p> */}
        </div>
      </div>
    </div>
  );
};
export const FilterSlidersShoes = ({ ...props }) => {
  //  const [shoe, setShoe] = useState([0, 46]);
  const shoeCursorMinDistance = 1;

  /* CURSOR SET - FILTER SHOE */
  const handleShoeChange = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }
    if (activeThumb === 0) {
      props.setShoe([
        Math.min(newValue[0], props.shoe[1] - shoeCursorMinDistance),
        props.shoe[1],
      ]);
    } else {
      props.setShoe([
        props.shoe[0],
        Math.max(newValue[1], props.shoe[0] + shoeCursorMinDistance),
      ]);
    }
    props.filterData();
  };

  return (
    <div className="slider-container">
      <div className="shoe-container">
        <div className="shoe-title">
          Shoes
          <div className="sliders-values">
            <p>
              {props.shoe[0]} - {props.shoe[1]}
            </p>
          </div>
        </div>
        <div className="shoe-cursor">
          {/* <p className="startvalue">{props.shoe[0]}</p> */}
          <Slider
            className="slider-content "
            getAriaLabel={() => "Minimum distance"}
            value={props.shoe}
            min={36}
            max={46}
            onChange={handleShoeChange}
            valueLabelDisplay="auto"
            disableSwap
          />
          {/* <p className="startvalue">{props.shoe[1]}</p> */}
        </div>
      </div>
    </div>
  );
};

export const FilterSlidersClothes = ({ ...props }) => {
  //  const [clothes, setClothes] = useState([0, 15]);
  const clothesCursorMinDistance = 0;

  /* CURSOR SET - FILTER CLOTHES */
  const handleClothesChange = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }
    if (activeThumb === 0) {
      props.setClothes([
        Math.min(newValue[0], props.clothes[1] - clothesCursorMinDistance),
        props.clothes[1],
      ]);
    } else {
      props.setClothes([
        props.clothes[0],
        Math.max(newValue[1], props.clothes[0] + clothesCursorMinDistance),
      ]);
    }
    props.filterData();
  };

  const marks = [
    {
      value: 1,
      label: "xs",
    },
    {
      value: 2,
      label: "xs/s",
    },
    {
      value: 3,
      label: "s",
    },
    {
      value: 4,
      label: "s/m",
    },
    {
      value: 5,
      label: "m",
    },
    {
      value: 6,
      label: "m/l",
    },
    {
      value: 7,
      label: "l",
    },
    {
      value: 8,
      label: "xl",
    },
    {
      value: 9,
      label: "xxl",
    },
  ];
  return (
    <div className="slider-container">
      <div className="clothes-container">
        <div className="clothes-title">Clothes</div>
        <div className="clothes-cursor">
          <Slider
            className="slider-content"
            getAriaLabel={() => "Minimum distance"}
            value={props.clothes}
            min={1}
            max={9}
            onChange={handleClothesChange}
            valueLabelDisplay="off"
            marks={marks}
            disableSwap
          />
        </div>
      </div>
    </div>
  );
};

export const FilterSlidersGender = ({ ...props }) => {
  // const [gender, setGender] = useState(``);
};
