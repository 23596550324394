import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const url = "http://localhost:3001/user";
const authUrl = "http://localhost:3001/auth";

const initialState = {
  test: "test",
  data: [],
  isLoading: true,
  isError: false,
  isSuccess: false,
};

export const addNewUser = createAsyncThunk(
  "adduser/addNewUser",
  async (argument, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`${url}/seq`);
      //console.log(data);
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      rejectWithValue(message);
    }
  }
);

const adduserSlice = createSlice({
  name: "adduser",
  initialState,
  reducers: {
    init: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addNewUser.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(addNewUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.data = action.payload;
      })
      .addCase(addNewUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});
//console.log(adduserSlice);

export default adduserSlice.reducer;
